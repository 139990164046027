import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FixedAssets } from '../../../../domain/types/Common';

const initialColorState: FixedAssets[] = [];

const colorSlice = createSlice({
  name: 'color',
  initialState: initialColorState,
  reducers: {
    setColors: (_state, action: PayloadAction<FixedAssets[]>) => {
      return action.payload;
    },
    clearColors: () => {
      return initialColorState;
    }
  }
});

export const { setColors, clearColors } = colorSlice.actions;
export default colorSlice.reducer;
