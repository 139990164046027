import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const configRoutes: RouteType[] = [
  {
    permissions: [
      'aplicante.create.permission',
      'aplicante.edit.permission',
      'aplicante.delete.permission',
      'aplicante.read.permission'
    ],
    path: urlRoutes.management.config.manual.applicant.urlTo,
    element: lazy(
      async () =>
        await import('ui/modules/management/config/manual/manualApplicant')
    )
  },

  {
    permissions: [
      'coordinador.create.permission',
      'coordinador.edit.permission',
      'coordinador.delete.permission',
      'coordinador.read.permission'
    ],
    path: urlRoutes.management.config.manual.coordinator.urlTo,
    element: lazy(
      async () =>
        await import('ui/modules/management/config/manual/manualCoordinator')
    )
  },

  {
    permissions: [
      'profesor.create.permission',
      'profesor.edit.permission',
      'profesor.delete.permission',
      'profesor.read.permission'
    ],
    path: urlRoutes.management.config.manual.teacher.urlTo,
    element: lazy(
      async () =>
        await import('ui/modules/management/config/manual/manualTeacher')
    )
  },
  {
    permissions: [
      'portada_principal.create.permission',
      'portada_principal.edit.permission',
      'portada_principal.delete.permission',
      'portada_principal.read.permission'
    ],
    path: urlRoutes.management.config.mainCover.urlTo,
    element: lazy(
      async () =>
        await import('ui/modules/management/config/mainCover/configMainCover')
    )
  },
  {
    permissions: [
      'portada_principal.create.permission',
      'portada_principal.edit.permission',
      'portada_principal.delete.permission',
      'portada_principal.read.permission'
    ],
    path: urlRoutes.management.config.reportHeader.urlTo,
    element: lazy(
      async () =>
        await import('ui/modules/management/config/reportHeader/configReportHeader')
    )
  },
]
