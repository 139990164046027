import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FixedAssets } from '../../../../domain/types/Common';

const initialBrandState: FixedAssets[] = [];

const brandSlice = createSlice({
  name: 'brand',
  initialState: initialBrandState,
  reducers: {
    setBrands: (_state, action: PayloadAction<FixedAssets[]>) => {
      return action.payload;
    },
    clearBrands: () => {
      return [];
    }
  }
});

export const { setBrands, clearBrands } = brandSlice.actions;
export default brandSlice.reducer;
