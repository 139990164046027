import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FixedAssets } from '../../../../domain/types/Common';

const initialTypologyState: FixedAssets[] = [];

const typologySlice = createSlice({
  name: 'typology',
  initialState: initialTypologyState,
  reducers: {
    setTypologies: (_state, action: PayloadAction<FixedAssets[]>) => {
      return action.payload;
    },
    clearTypologies: () => {
      return initialTypologyState;
    }
  }
});

export const { setTypologies, clearTypologies } = typologySlice.actions;
export default typologySlice.reducer;
