import { Navigate, Outlet } from 'react-router-dom'

type IProps = {
  isLoggedIn: boolean
}

const PublicRoutes = ({ isLoggedIn }: IProps) => {
  return !isLoggedIn ? <Outlet /> : <Navigate to='/' />
}

export default PublicRoutes
