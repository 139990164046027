import type { RouteType } from 'core/util/httpClient/domain/types/common'
import { lazy, useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import LayoutManagement from 'ui/shared/components/layouts/management/layoutManagement'

import { academicRoutes } from './academic'
import { administrativeRoutes } from 'ui/routers/privatedPages/administrative'
import { admissionsRoutes } from 'ui/routers/privatedPages/admissions'
import { configRoutes } from 'ui/routers/privatedPages/config'
import { homeRoutes } from 'ui/routers/privatedPages/home'
import { infosysRoutes } from 'ui/routers/privatedPages/infosys'
import { profileRoutes } from 'ui/routers/privatedPages/profile'
import { securityRoutes } from 'ui/routers/privatedPages/security'
import { AuthContext } from 'ui/contexts/auth/authProvider'
import WithoutPermissions from 'ui/modules/auth/withoutPermissions/withoutPermissions'
import { rols } from 'ui/constant/rols'
import LayoutApplicant from 'ui/shared/components/layouts/applicant/layoutApplicant'
import { studentRoutes } from './student'
import { useUserSelectors } from 'core/util/stateManager/application/selectors/UserSelector'

const NotFoundPrivate = lazy(
  async () => await import('ui/modules/404/private/privateError')
)

export const privateRoutes = [
  ...homeRoutes,
  ...profileRoutes,
  ...academicRoutes,
  ...admissionsRoutes,
  ...administrativeRoutes,
  ...infosysRoutes,
  ...configRoutes,
  ...securityRoutes
]

export default function PrivatedPages() {
  const { nonePermissions } = useContext(AuthContext)
  const { userSelector } = useUserSelectors()

  return nonePermissions ? (
    <WithoutPermissions />
  ) : userSelector?.role === rols.applicant ||
    userSelector?.role === rols.student || userSelector?.role === rols.teacherApplicant ? (
      <LayoutApplicant>
        <Routes>
          <Route path="*" element={ <NotFoundPrivate /> } />
          { studentRoutes.map((routes: RouteType) => (
            <Route
              key={ routes.path }
              path={ routes.path } 
              element={ <routes.element /> }
            />
          )) }
        </Routes>
      </LayoutApplicant>
    ) : (
      <LayoutManagement>
        <Routes>
          <Route path="*" element={ <NotFoundPrivate /> } />
          { privateRoutes.map((routes: RouteType) => {
            return (
              <Route
                key={ routes.path }
                path={ routes.path }
                element={ routes.extra ? 
                  <routes.element permissions={ routes.permissions } { ...routes.extra } />
                  :<routes.element permissions={ routes.permissions } /> }
              />
            )
          }) }
        </Routes>
      </LayoutManagement>
    )
}
