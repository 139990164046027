import { urlRoutes } from 'ui/constant/urls'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined'
import FlightIcon from '@mui/icons-material/Flight';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import SupervisedUserCircleOutlinedIcon from '@mui/icons-material/SupervisedUserCircleOutlined'
import ModelTrainingIcon from '@mui/icons-material/ModelTraining'
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined'
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined'
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined'
import PanoramaOutlinedIcon from '@mui/icons-material/PanoramaOutlined'
import QuizIcon from '@mui/icons-material/Quiz'
import EventIcon from '@mui/icons-material/Event';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

export const drawerList = () => {
  return [
    {
      items: [
        {
          icon: <DashboardOutlinedIcon />,
          text: 'Dashboard',
          permissions: ['dashboard.read.permission'],
          link: urlRoutes.management.dashboard.urlTo,
          children: []
        }
      ]
    },
    {
      module: 'Académico',
      items: [
        {
          itemId: 0,
          icon: <ModelTrainingIcon />,
          text: 'Capacitación',
          permissions: ['creacion_de_evento.read.permission', 'registro_de_capacitacion.read.permission'],
          children: [
            { text: 'Creación de Evento', link: urlRoutes.management.academic.training.event.list.urlTo, permission: 'creacion_de_evento.read.permission' },
            { text: 'Registro de capacitación', link: urlRoutes.management.academic.training.register.list.urlTo, permission: 'registro_de_capacitacion.read.permission' }
          ]
        },
        {
          itemId: 1,
          icon: <WorkspacesOutlinedIcon />,
          text: 'Centros',
          permissions: [
            'creacion_de_aula.read.permission',
            'asignacion_de_estudiantes_a_aula.read.permission',
            'asignacion_de_coordinador.read.permission',
            'examenes.read.permission',
            'estudiantes.read.permission',
            'pase_de_lista_de_profesor.read.permission',
            'tardanza_y_excusas.read.permission',
            'registro_y_control_asistencia_profesor.read.permission',
            'solicitud_calculo_de_calificaciones.read.permission',
            'solicitud_de_inicio_de_nivel.read.permission',
            'solicitud_activo_fijo.read.permission',
            'solicitud_mantenimiento_planta_fisica.read.permission',
            'solicitud_mantenimiento_laboratorio.read.permission',
            'soporte_tecnico.read.permission', 
            'asignacion_libros_estudiantes.read.permission'],
          children: [
            { text: 'Creación de Aula', link: urlRoutes.management.academic.seat.classroom.list.urlTo, permission: 'creacion_de_aula.read.permission' },
            { text: 'Asignación de estudiantes a aula', link: urlRoutes.management.academic.seat.studentClassroom.list.urlTo, permission: 'asignacion_de_estudiantes_a_aula.read.permission' },
            { text: 'Asignación de coordinador', link: urlRoutes.management.academic.intitutions.asignations.coordinator.list.urlTo, permission: 'asignacion_de_coordinador.read.permission' },
            { text: 'Exámenes', link: urlRoutes.management.academic.seat.examen.list.urlTo, permission: 'examenes.read.permission' },
            // { text: 'Banco  de exámenes', link: urlRoutes.management.academic.seat.bankExams.list.urlTo, permission: 'examenes.read.permission' },
            { text: 'Consulta de Estudiantes', link: urlRoutes.management.academic.seat.student.urlTo, permission: 'estudiantes.read.permission' },
            { text: 'Pase de lista de profesor', link: urlRoutes.management.academic.seat.teacherPassList.urlTo, permission: 'pase_de_lista_de_profesor.read.permission' },
            { text: 'Tardanzas y Excusas', link: urlRoutes.management.academic.seat.tardiesExcuses.urlTo, permission: 'tardanza_y_excusas.read.permission' },
            { text: 'Registro y control asistencia profesor', link: urlRoutes.management.academic.seat.registrationTeacherAttendanceControl.urlTo, permission: 'registro_y_control_asistencia_profesor.read.permission' },
            { text: 'Registro de calificaciones', link: urlRoutes.management.academic.seat.registerQualifications.urlTo, permission: 'registro_de_calificaciones.read.permission' },
            { text: 'Solicitud cálculo de calificaciones', link: urlRoutes.management.academic.seat.gradeCalculationRequest.list.urlTo, permission: 'solicitud_calculo_de_calificaciones.read.permission' },
            { text: 'Solicitud de inicio de nivel', link: urlRoutes.management.academic.seat.startLevelRequest.list.urlTo, permission: 'solicitud_de_inicio_de_nivel.read.permission' },
            { text: 'Solicitud descargo de activos fijos', link: urlRoutes.management.academic.seat.dischargeFixedAssets.list.urlTo, permission: 'solicitud_activo_fijo.read.permission' },
            { text: 'Solicitud de mantenimiento de planta', link: urlRoutes.management.academic.seat.physicalPlantMaintenance.list.urlTo, permission: 'solicitud_mantenimiento_planta_fisica.read.permission' },
            { text: 'Solicitud de mantenimiento de laboratorios', link: urlRoutes.management.academic.seat.laboratoryMaintenanceRequest.list.urlTo, permission: 'solicitud_mantenimiento_laboratorio.read.permission' },
            { text: 'Soporte técnico', link: urlRoutes.management.academic.seat.technicalSupport.list.urlTo, permission: 'soporte_tecnico.read.permission' },
            { text: 'Asignación de libros a estudiantes', link: urlRoutes.management.academic.seat.assigningBooksStudents.list.urlTo, permission: 'asignacion_libros_estudiantes.read.permission' }
          ]
        },
        {
          itemId: 2,
          icon: <TransferWithinAStationOutlinedIcon />,
          text: 'Evaluaciones',
          permissions: ['apertura_docente.read.permission', 'estatus_de_apertura_docente.read.permission'],
          children: [
            { text: 'Convocatoria Docente', link: urlRoutes.management.academic.evaluation.teachingCreation.list.urlTo, permission: 'apertura_docente.read.permission' },
            { text: 'Estatus de convocatoria docente', link: urlRoutes.management.academic.evaluation.teachingStatus.list.urlTo, permission: 'estatus_de_apertura_docente.read.permission' }
          ]
        },
        {
          itemId: 3,
          icon: <SupervisedUserCircleOutlinedIcon />,
          text: 'Supervisión',
          permissions: [
            'asignacion_de_supervisor.read.permission',
            'creacion_de_preguntas.read.permission',
            'configuracion_de_visitas.read.permission',
            'registro_de_visitas_centro.read.permission'],
          children: [
            { text: 'Asignación de supervisor', link: urlRoutes.management.academic.supervision.supervisor.list.urlTo, permission: 'asignacion_de_supervisor.read.permission' },
            { text: 'Creación de preguntas', link: urlRoutes.management.academic.supervision.question.list.urlTo, permission: 'creacion_de_preguntas.read.permission' },
            { text: 'Configuración de visitas', link: urlRoutes.management.academic.supervision.questionBank.list.urlTo, permission: 'configuracion_de_visitas.read.permission' },
            { text: 'Registro de visitas centro', link: urlRoutes.management.academic.supervision.registerVisit.list.urlTo, permission: 'registro_de_visitas_centro.read.permission' }
          ]
        }
      ]
    },
    {
      module: 'Admisiones',
      items: [
        {
          itemId: 4,
          icon: <FolderOutlinedIcon />,
          text: 'Convocatoria',
          permissions: [
            'estatus_de_convocatoria.read.permission',
            'estatus_de_aplicante.read.permission',
            'validacion.read.permission',
            'validacion_docente.read.permission',
            'solicitante.read.permission',
            'cambio_estado.read.permission',
            'movimientos.read.permission',
            'solicitud_calculo_de_calificaciones.read.permission',
            'estado_graduado.read.permission',
            'solicitud_de_inicio_de_nivel.read.permission',
            'asignacion_de_soporte_de_admisiones.read.permission',
            'asignacion_de_profesores.read.permission',
            'asignacion_de_profesor_encargado.read.permission',
            'pase_de_lista_de_nivel.read.permission',
            'inicio_nivel.read.permission'],
          children: [
            { text: 'Estatus de convocatoria', link: urlRoutes.management.admissions.announcement.status.urlTo, permission: 'estatus_de_convocatoria.read.permission' },
            { text: 'Estatus de aplicante', link: urlRoutes.management.admissions.announcement.statusApplicant.urlTo, permission: 'estatus_de_aplicante.read.permission' },
            { text: 'Validación', link: urlRoutes.management.admissions.announcement.validation.list.urlTo, permission: 'validacion.read.permission' },
            { text: 'Validación Docente', link: urlRoutes.management.admissions.announcement.validationTeacher.list.urlTo, permission: 'validacion_docente.read.permission' },
            { text: 'Solicitante', link: urlRoutes.management.admissions.announcement.students.add.urlTo, permission: 'solicitante.read.permission' },
            { text: 'Cambio Estado', link: urlRoutes.management.admissions.announcement.changeStatus.urlTo, permission: 'cambio_estado.read.permission' },
            { text: 'Movimientos', link: urlRoutes.management.academic.intitutions.movements.urlTo, permission: 'movimientos.read.permission' },
            { text: 'Calculo de Calificaciones', link: urlRoutes.management.admissions.announcement.qualifications.urlTo, permission: 'solicitud_calculo_de_calificaciones.read.permission' },
            { text: 'Estado graduado', link: urlRoutes.management.academic.intitutions.graduate.urlTo, permission: 'estado_graduado.read.permission' },
            { text: 'Inicio Nivel', link: urlRoutes.management.admissions.announcement.level.list.urlTo, permission: 'inicio_nivel.read.permission' },
            { text: 'Asignación de soporte de admisiones', link: urlRoutes.management.admissions.announcement.technical.list.urlTo, permission: 'asignacion_de_soporte_de_admisiones.read.permission' },
            { text: 'Asignación de profesores', link: urlRoutes.management.admissions.announcement.teacher.list.urlTo, permission: 'asignacion_de_profesores.read.permission' },
            { text: 'Asignación de profesor encargado', link: urlRoutes.management.admissions.announcement.manager.list.urlTo, permission: 'asignacion_de_profesor_encargado.read.permission' },
            { text: 'Pase de lista de nivel', link: urlRoutes.management.admissions.announcement.passListLevel.urlTo, permission: 'pase_de_lista_de_nivel.read.permission' }
          ]
        },

        {
          itemId: 5,
          icon: <SettingsOutlinedIcon />,
          text: 'Configuración',
          permissions: [
            'idioma.read.permission',
            'motivo_de_transferencia.read.permission',
            'modalidad.read.permission',
            'centros.read.permission',
            'convocatoria.read.permission',
            'seleccion_de_cupos.read.permission',
            'creacion_de_nivel.read.permission',
            'convocatoria_nivel.read.permission',
            'items_de_evaluacion.read.permission'],
          children: [
            { text: 'Idioma', link: urlRoutes.management.admissions.setting.language.list.urlTo, permission: 'idioma.read.permission' },
            { text: 'Motivo de Transferencia', link: urlRoutes.management.admissions.setting.transferReason.list.urlTo, permission: 'motivo_de_transferencia.read.permission' },
            { text: 'Modalidad de centros', link: urlRoutes.management.admissions.setting.modality.list.urlTo, permission: 'modalidad.read.permission' },
            { text: 'Centros', link: urlRoutes.management.admissions.setting.seat.urlTo, permission: 'centros.read.permission' },
            { text: 'Convocatoria', link: urlRoutes.management.admissions.setting.announcement.list.urlTo, permission: 'convocatoria.read.permission' },
            { text: 'Selección de cupos', link: urlRoutes.management.admissions.setting.quota.list.urlTo, permission: 'seleccion_de_cupos.read.permission' },
            { text: 'Creación de nivel', link: urlRoutes.management.admissions.setting.level.list.urlTo, permission: 'creacion_de_nivel.read.permission' },
            { text: 'Convocatoria - Nivel', link: urlRoutes.management.admissions.setting.levelAnnoucement.list.urlTo, permission: 'convocatoria_nivel.read.permission' },
            { text: 'Items de evaluación', link: urlRoutes.management.admissions.setting.evaluation.list.urlTo, permission: 'items_de_evaluacion.read.permission' }
          ]
        }
      ]
    },
    {
      module: 'Administrativo',
      items: [
        {
          icon: <ForwardToInboxOutlinedIcon />,
          text: 'Correspondencia',
          permissions: ['correspondencia.read.permission'],
          link: urlRoutes.management.administrative.correspondence.urlTo,
          children: []
        },
        {
          icon: <LocalGasStationOutlinedIcon />,
          text: 'Combustible',
          permissions: ['combustible.read.permission'],
          link: urlRoutes.management.administrative.fuel.urlTo,
          children: []
        },
        {
          icon: <FlightIcon />,
          text: 'Viáticos y viajes',
          permissions: ['combustible.read.permission'],
          link: urlRoutes.management.administrative.travel.urlTo,
          children: []
        },
        {
          itemId: 7,
          icon: <BadgeOutlinedIcon />,
          text: 'Empleados',
          permissions: [
            'registro.read.permission',
            'control_de_renuncia.read.permission',
            'renuncia.read.permission',
            'control_de_traslado.read.permission',
            'traslado.read.permission'],
          children: [
            { text: 'Registro', link: urlRoutes.management.administrative.employee.register.urlTo, permission: 'registro.read.permission' },
            { text: 'Control de renuncia', link: urlRoutes.management.administrative.employee.resignation.urlTo, permission: 'control_de_renuncia.read.permission' },
            { text: 'Renuncia', link: urlRoutes.management.administrative.employee.resignation.create.urlTo, permission: 'renuncia.read.permission' },
            { text: 'Control de traslado', link: urlRoutes.management.administrative.employee.transfer.urlTo, permission: 'control_de_traslado.read.permission' },
            { text: 'Traslado', link: urlRoutes.management.administrative.employee.transfer.create.urlTo, permission: 'traslado.read.permission' }
          ]
        },
        {
          itemId: 8,
          icon: <MenuBookOutlinedIcon />,
          text: 'Registro y control de libros',
          permissions: ['almacen.read.permission', 'agregar_libros_al_almacen.read.permission', 'consulta_de_solicitud.read.permission'],
          children: [
            { text: 'Almacén', link: urlRoutes.management.administrative.registrationControlBooks.store.list.urlTo, permission: 'almacen.read.permission' },
            { text: 'Agregar libros al almacén', link: urlRoutes.management.administrative.registrationControlBooks.addBookToStore.list.urlTo, permission: 'agregar_libros_al_almacen.read.permission' },
            { text: 'Consulta de Solicitud', link: urlRoutes.management.administrative.registrationControlBooks.requestBook.list.urlTo, permission: 'consulta_de_solicitud.read.permission' }
          ]
        },
        {
          icon: <KeyboardAltOutlinedIcon />,
          text: 'Caja chica',
          permissions: ['caja_chica.read.permission'],
          link: urlRoutes.management.cash.urlTo,
          children: []
        },
        {
          icon: <DescriptionOutlinedIcon />,
          text: 'Facturas',
          permissions: ['facturas.read.permission'],
          link: urlRoutes.management.invoices.urlTo,
          children: []
        },
        {
          icon: <ReceiptOutlinedIcon />,
          text: 'Contratos',
          permissions: ['contratos.read.permission'],
          link: urlRoutes.management.contracts.urlTo,
          children: []
        },
        {
          icon: <HistoryEduOutlinedIcon />,
          text: 'Carta de Constancia',
          permissions: ['carta_constancia.read.permission'],
          link: urlRoutes.management.proofStudyLetter.urlTo,
          children: []
        },
        {
          icon: <WorkspacePremiumOutlinedIcon />,
          text: 'Certificados de Graduado',
          permissions: ['certificado.read.permission'],
          link: urlRoutes.management.certificate.urlTo,
          children: []
        },
        {
          itemId: 9,
          icon: <SettingsOutlinedIcon />,
          text: 'Configuración',
          permissions: [
            'activos_fijos.read.permission',
            'creacion_activo_fijo.read.permission',
            'correspondencia.read.permission',
            'viaticos_y_viajes.read.permission',
            'registro_de_libros.read.permission',
            'materiales_y_libros.read.permission',
            'posicion.read.permission',
            'carreras.read.permission',
            'universidades.read.permission'],
          children: [
            { text: 'Activos fijos', link: urlRoutes.management.administrative.setting.fixedassets.urlTo, permission: 'activos_fijos.read.permission' },
            { text: 'Creación Activos fijos', link: urlRoutes.management.administrative.setting.fixedAssetsRegistry.urlTo, permission: 'creacion_activo_fijo.read.permission' },
            { text: 'Correspondencia', link: urlRoutes.management.administrative.setting.correspondence.urlTo, permission: 'correspondencia.read.permission' },
            { text: 'Viáticos y viajes', link: urlRoutes.management.administrative.setting.perDiemAndTravel.urlTo, permission: 'viaticos_y_viajes.read.permission' },
            { text: 'Registro de libros', link: urlRoutes.management.administrative.setting.bookRecord.urlTo, permission: 'registro_de_libros.read.permission' },
            { text: 'Materiales y libros', link: urlRoutes.management.administrative.setting.materialsBooks.urlTo, permission: 'materiales_y_libros.read.permission' },
            { text: 'Mantenimiento', link: urlRoutes.management.administrative.setting.maintenance.urlTo, permission: 'mantenimiento.read.permission' },
            { text: 'Posición', link: urlRoutes.management.administrative.setting.position.urlTo, permission: 'posicion.read.permission' },
            { text: 'Carreras', link: urlRoutes.management.administrative.setting.careers.list.urlTo, permission: 'carreras.read.permission' },
            { text: 'Universidades', link: urlRoutes.management.administrative.setting.universities.list.urlTo, permission: 'universidades.read.permission' }
          ]
        }
      ]
    },
    {
      module: 'Infosys',
      items: [
        {
          itemId: 90,
          icon: <QuizIcon />,
          text: 'Encuestas',
          permissions: ['encuestas.read.permission'],
          children: [
            { text: 'Encuestas', link: urlRoutes.management.infosys.surveys.survey.urlTo, permission: 'encuestas.read.permission' },
            { text: 'Aplicar Encuestas', link: urlRoutes.management.infosys.surveys.surveyApplication.urlTo, permission: 'encuestas.read.permission' },
            { text: 'Resultado de Encuestas', link: urlRoutes.management.infosys.surveys.surveyResults.urlTo, permission: 'encuestas.read.permission' },
          ]
        },
        {
          itemId: 10,
          icon: <FactCheckOutlinedIcon />,
          text: 'Reportes',
          permissions: ['estudiantes_por_centros.read.permission', 'convocatoria_infosys.read.permission', 'informacion_de_centros.read.permission'],
          children: [
            { text: 'General', link: urlRoutes.management.infosys.report.general.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'General por Estado, Provincia, Centro y Genero', link: urlRoutes.management.infosys.report.genero.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'General por Centro', link: urlRoutes.management.infosys.report.centro.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Eventos de Capacitación', link: urlRoutes.management.infosys.report.evento.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Transferencias', link: urlRoutes.management.infosys.report.transferencia.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Retiros', link: urlRoutes.management.infosys.report.retiro.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Cantidad de Estudiantes por Centro', link: urlRoutes.management.infosys.report.estudiante.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Calificación de Estudiantes por Centro', link: urlRoutes.management.infosys.report.calificacion.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Calificaciones Promedio por Centro, Tanda y Aula', link: urlRoutes.management.infosys.report.calificacioncenter.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Caja Chica', link: urlRoutes.management.infosys.report.cajachica.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Combustible', link: urlRoutes.management.infosys.report.combustible.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Correspondencia', link: urlRoutes.management.infosys.report.correspondencia.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Pase de Lista de Nivel / Admisiones', link: urlRoutes.management.infosys.report.paselista.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Activos Fijos por Naturaleza', link: urlRoutes.management.infosys.report.activosfijosnaturaleza.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Activos Fijos por Localidad', link: urlRoutes.management.infosys.report.activosfijoslocalidad.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Historial de Promedio por Centro, Tanda y Profesores', link: urlRoutes.management.infosys.report.historialpromedioprofesor.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Historial General de Promedio por Estudiantes', link: urlRoutes.management.infosys.report.historialpromedioestudiante.urlTo, permission: 'estudiantes_por_centros.read.permission' },
            { text: 'Convocatoria', link: urlRoutes.management.infosys.report.announcement.urlTo, permission: 'convocatoria_infosys.read.permission' },
            { text: 'Información de Centros', link: urlRoutes.management.infosys.report.centerInformation.urlTo, permission: 'informacion_de_centros.read.permission' }
          ]
        },
        {
          itemId: 91,
          icon: <EventIcon />,
          text: 'Calendario',
          permissions: [
            'calendario_academico.read.permission',
            'calendario_admisiones.read.permission'
          ],
          children: [
            { text: 'Calendario Académico', link: urlRoutes.management.infosys.calendar.academicCalendar.urlTo, permission: 'calendario_academico.read.permission' },
            { text: 'Calendario Admisiones', link: urlRoutes.management.infosys.calendar.admissionsCalendar.urlTo, permission: 'calendario_admisiones.read.permission' },
          ]
        },
        {
          itemId: 92,
          icon: <EventNoteIcon />,
          text: 'Agenda',
          permissions: [
            'agenda_electronica.read.permission',
          ],
          children: [
            { text: 'Agenda Electronica', link: urlRoutes.management.infosys.agenda.electronicAgenda.urlTo, permission: 'agenda_electronica.read.permission' },
          ]
        },
      ]
    },
    {
      module: 'Configuración',
      items: [
        {
          itemId: 11,
          icon: <SummarizeOutlinedIcon />,
          text: 'Manuales',
          permissions: ['aplicante.read.permission', 'coordinador.read.permission', 'profesor.read.permission'],
          children: [
            { text: 'Aplicante', link: urlRoutes.management.config.manual.applicant.urlTo, permission: 'aplicante.read.permission' },
            { text: 'Coordinador', link: urlRoutes.management.config.manual.coordinator.urlTo, permission: 'coordinador.read.permission' },
            { text: 'Profesor', link: urlRoutes.management.config.manual.teacher.urlTo, permission: 'profesor.read.permission' }
          ]
        },
        {
          icon: <PanoramaOutlinedIcon />,
          text: 'Portada principal',
          permissions: ['portada_principal.read.permission'],
          link: urlRoutes.management.config.mainCover.urlTo,
          children: []
        },
        {
          icon: <PhotoLibraryOutlinedIcon />,
          text: 'Etiqueta de reporte',
          permissions: ['portada_principal.read.permission'],
          link: urlRoutes.management.config.reportHeader.urlTo,
          children: []
        },
      ]
    },
    {
      module: 'Seguridad y Protección',
      items: [
        {
          itemId: 12,
          icon: <AdminPanelSettingsOutlinedIcon />,
          text: 'Seguridad',
          permissions: ['roles.read.permission', 'modulos.read.permission'],
          children: [
            { text: 'Roles', link: urlRoutes.security.roles.urlTo, permission: 'roles.read.permission' },
            { text: 'Módulos', link: urlRoutes.security.modules.urlTo, permission: 'modulos.read.permission' }
          ]
        }
      ]
    }
  ]
}
