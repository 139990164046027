import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined'
import { Divider } from '@mui/material'

import './style.css'

import Logo from 'assets/logo.png'
import { drawerList } from 'ui/constant/drawer'
import { NavLink } from 'react-router-dom'
import AccountMenu from './accountMenu'
import { AuthContext } from 'ui/contexts/auth/authProvider'

const drawerWidth = 240

type Props = {
  window?: () => Window
  children: React.ReactNode
}

type DrawerTypeItemChildren = {
  text: string
  link: string
  permission: string
}

type DrawerTypeItem = {
  itemId?: number
  icon: JSX.Element
  text: string
  permissions: string[]
  link?: string
  children: DrawerTypeItemChildren[]
}

type DrawerType = {
  module?: string
  items: DrawerTypeItem[]
}

export default function LayoutManagement (props: Props) {
  const { window, children } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [open, setOpen] = React.useState(-1)

  const {  hasPermissions } = React.useContext(AuthContext)

  const handleClick = (index: any) => () => {
    if (index === open) {
      setOpen(-1)
    } else {
      setOpen(index)
    }
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const newDrawerList: DrawerType[] = drawerList()

  const drawer = (
    <div>
      <Box my={ 1 } display='flex' justifyContent='center'>
        <img alt='Logo' width={ 175 } src={ Logo } />
      </Box>
      <List>
        { newDrawerList.filter(e => hasPermissions(e.items.flatMap(e => e.permissions), false)).map((data: DrawerType, index: number) => (
          <div key={ index }>
            { data.module &&
            <Divider /> }
            <Typography variant='body2' style={ { fontWeight: '500' } } m={ 1 }>
              { data.module }
            </Typography>
            { data.items.filter(e => hasPermissions(e.permissions, false)).map((dataItems: DrawerTypeItem, index: number) => (
              !dataItems.children[0]? <NavLink
                key={ index }
                to={ dataItems.link ?? '' }
                className={ ({ isActive }) => isActive ? 'activeStyle' : 'notActiveStyle' }
              >
                <ListItemButton>
                  <ListItemIcon sx={ { minWidth: '40px' } }>
                    { dataItems.icon }
                  </ListItemIcon>
                  <ListItemText primary={ dataItems.text } />
                </ListItemButton>
              </NavLink>
                : <div key={ index }>

                  <ListItemButton
                    style={ {
                      background: open === dataItems.itemId ? '#003876' : '',
                      color: open === dataItems.itemId ? '#fff' : '#003876'
                    } }
                    onClick={ handleClick(dataItems.itemId) }
                  >
                    <ListItemIcon
                      style={ {
                        background: open === dataItems.itemId ? '#003876' : '',
                        color: open === dataItems.itemId ? '#fff' : '#003876'
                      } }
                      sx={ { minWidth: '40px' } }
                    >
                      { dataItems.icon }
                    </ListItemIcon>
                    <ListItemText primary={ dataItems.text } />
                    { open === dataItems.itemId ? <ExpandLess /> : <ExpandMore /> }
                  </ListItemButton>

                  <Collapse
                    in={ open === dataItems.itemId }
                    timeout='auto'
                    unmountOnExit
                    sx={ { background: '#e8f4ff' } }
                  >
                    <List component='div' disablePadding>
                      { dataItems.children.filter(e => hasPermissions([e.permission], true)).map((dataChildren: DrawerTypeItemChildren, index: number) => (
                        <NavLink
                          key={ index }
                          to={ dataChildren.link }
                          className={ ({ isActive }) => isActive ? 'activeStyle' : 'notActiveStyle' }
                        >
                          <ListItemButton
                            sx={ { pl: 4 } }
                          >
                            <ListItemIcon sx={ { minWidth: '20px' } }>
                              <CircleOutlinedIcon style={ { fontSize: '12px' } } />
                            </ListItemIcon>
                            <ListItemText primary={ dataChildren.text } />
                          </ListItemButton>
                        </NavLink>
                      )) }
                    </List>
                  </Collapse>
                </div>
            )) }
          </div>
        )) }
      </List>
    </div>
  )

  const container = window !== undefined ? () => window().document.body : undefined

  return (
    <Box sx={ { display: 'flex' } }>
      <CssBaseline />
      <AppBar
        position='fixed'
        sx={ {
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        } }
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={ handleDrawerToggle }
            sx={ { mr: 2, display: { sm: 'none' } } }
          >
            <MenuIcon color='primary' />
          </IconButton>
          <Typography variant='h6' noWrap component='div' sx={ { flexGrow: 1 } } />

          <div>
            <AccountMenu />
          </div>

        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={ { width: { sm: drawerWidth }, flexShrink: { sm: 0 } } }
        aria-label='mailbox folders'
      >
        <Drawer
          container={ container }
          variant='temporary'
          open={ mobileOpen }
          onClose={ handleDrawerToggle }
          ModalProps={ {
            keepMounted: true
          } }
          sx={ {
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          } }
        >
          { drawer }
        </Drawer>
        <Drawer
          variant='permanent'
          sx={ {
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
          } }
          open
        >
          { drawer }
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={ { flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } } }
      >
        <Toolbar />
        { children }
      </Box>
    </Box>
  )
}
