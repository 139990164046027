export const authReducer = (state: any, action: any): any => {
  switch (action.type) {
  case 'authUser':
    return {
      auth: action.payload
    }

  default:
    return state
  }
}
