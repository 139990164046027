import { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { AuthContext } from "ui/contexts/auth/authProvider";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { Badge } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { LoadingData } from "ui/shared/components/common/Loading/Loading";
import type { Notification } from "core/notification/domain/response/notification.type";
import type NotificationApplication from "core/notification/application/notification";
import type { CustomResponse } from "core/domain/common";
import type { AxiosResponse } from "axios";

const NOTIFICATION_INTERVAL = 20000;
const NOTIFICATION_QUANTITY = 10;

type AccountMenuProps = {
  service: NotificationApplication,
  onNotificationMenuClick?:()=>void,
  onNotificationMenuClose?:()=>void,
  onFetchNotification?:()=>void,
  interval?:number;
}

export default function NotificationMenu({ service, onNotificationMenuClick, onNotificationMenuClose,onFetchNotification, interval = NOTIFICATION_INTERVAL }: AccountMenuProps) {

  const { authState } = useContext(AuthContext);

  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false)
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [budge, setBudge] = useState<number>(0);
  const [anchorElNoti, setAnchorElNoti] = useState<null | HTMLElement>(null);
  const myref = useRef<null | HTMLDivElement>(null)

  const isNotificationOpen = Boolean(anchorElNoti);

  const uniquesIds = (list:Notification[])=>{
    const uniqueSet = new Set();
    return list.filter(item=>{
      if(!uniqueSet.has(item.id)){
        uniqueSet.add(item.id);
        return true;
      }
      return false;
    });

  }

  const fetchNotifications = () => {
    const userId = authState? authState.auth.user.id : '';
    service.executeGetNotification({ id:userId, page, quantity:NOTIFICATION_QUANTITY }).then((res:AxiosResponse<CustomResponse<Notification>>|undefined) => {
      res && setNotifications((prev: Notification[])=>uniquesIds([...prev,...res.data.records]));
      res && setBudge([...notifications,...res.data.records].filter((x: { read: boolean; })=>!x.read).length);
      res && res.data.records.filter((x: { read: boolean; })=>!x.read).forEach((item: Notification) => {
        service.executeMarkAsRead(item.id);
      });
      onFetchNotification?.();
    }).finally(()=>{ setLoading(false); }); 
  }
  
  useEffect(() => {
    fetchNotifications();

    const notificationInterval = setInterval(() => {
      fetchNotifications();
    }, interval);

    return () => {
      clearInterval(notificationInterval);
    };
  }, [page]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const element = e.target as HTMLDivElement;
    const limit = myref.current?.clientHeight;
    const offset = element.offsetHeight + element.scrollTop;
    if(limit === offset) setPage(page + 1);
  };

  const onClickNotificationHook = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNoti(event.currentTarget);
    onNotificationMenuClick?.();
  };

  const closeNotificationHook = () => {
    setAnchorElNoti(null);
    onNotificationMenuClose?.();
  };

  const deleteNotificationHook = (id:string) => {
    setLoading(true);
    service.executeDeleteNotification(id).then(()=>{
      setNotifications((prev: Notification[])=>prev.filter(e=>e.id !== id))
      setPage(Math.ceil(notifications.length / NOTIFICATION_QUANTITY)); 
      setLoading(false); 
    });
  }

  return (
    <>
      <Box sx={ { display: "flex", alignItems: "center", textAlign: "center" } }>
        <div>
          <Tooltip title="Notificaciones">
            <Button
              onClick={ onClickNotificationHook }
              size="small"
              sx={ { ml: 0 } }
              aria-controls={ isNotificationOpen ? "noti-menu" : undefined }
              aria-haspopup="true"
              aria-expanded={ isNotificationOpen ? "true" : undefined }
            >
              <Badge badgeContent={ budge } color="error">
                <NotificationsIcon />
              </Badge>
            </Button>
          </Tooltip>
        </div>
      </Box>
      <Menu
        anchorEl={ anchorElNoti }
        id="noti-menu"
        open={ isNotificationOpen }
        onClose={ closeNotificationHook }
        PaperProps={ {
          elevation: 0,
          sx: {
            width:300,
            height:400,
            position:"relative",
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        } }
        transformOrigin={ { horizontal: "right", vertical: "top" } }
        anchorOrigin={ { horizontal: "right", vertical: "bottom" } }
      >
        <div style={ { overflowY:'auto', height: 390, cursor:'default' } } onScroll={ handleScroll } >
          { loading ? <div style={ { display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%' } }><LoadingData size={ 25 }/></div>: 
            notifications.length > 0 ? 
              <div ref={ myref } >
                { notifications?.map((e: Notification) => (
                  <>
                    <div key={ e.id } style={ { padding:8, position:'relative' } }>
                      <button title="Borrar" style={ { position:'absolute', top:0, right:0, marginRight:10, backgroundColor:'transparent', borderRadius:25, padding:1, border:0, cursor:'pointer' } } onClick={ ()=>{ deleteNotificationHook(e.id); } }><DeleteIcon color="primary" fontSize="small"/></button>
                      <div style={ { lineHeight:1.2, fontSize:'.8rem', fontWeight:e.read?500:700, marginBottom:10, marginTop:10, marginRight:10 } } dangerouslySetInnerHTML={ { __html: e.message } }/>
                      <div style={ { bottom:0, right:0, marginRight:10, fontSize:'.65rem', position:'absolute', color:'rgba(0,0,0,.7)' } }>{ new Date(e.createdAt).toDateString() }</div>
                    </div>
                    <Divider />
                  </>
                )) }</div> : <div style={ { display:'flex', justifyContent:'center', alignItems:'center', width:'100%', height:'100%' } }>No hay notificaciones</div> }
          
        </div>
      </Menu>
    </>
  );
}
