import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const profileRoutes: RouteType[] = [
  {
    permissions: [
      'perfil.create.permission',
      'perfil.edit.permission',
      'perfil.delete.permission',
      'perfil.read.permission'
    ],
    path: urlRoutes.management.profile.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/profile/managementProfile')
    )
  }
]
