import { lazy } from 'react'
import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const infosysRoutes: RouteType[] = [
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.general.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'general',
      reportDisplayName:'General de Registro',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'institution',pType:'shape',required:true },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'status',pType:'shape',required:true },
        { name:'minAge',pType:'number',required:false },
        { name:'maxAge',pType:'number',required:false },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.genero.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'genero',
      reportDisplayName:'General de Registro Por Estado, Provincia y Genero',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.centro.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'centro',
      reportDisplayName:'General por Centro',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.evento.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'evento',
      reportDisplayName:'Eventos de Capacitación',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'institution',pType:'shape',required:true },
        { name:'activity',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.transferencia.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'transferencia',
      reportDisplayName:'Transferencias',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.retiro.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'retiro',
      reportDisplayName:'Retiros',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.estudiante.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'estudiante',
      reportDisplayName:'Cantidad de Estudiantes por Centro',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.calificacion.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'calificacion',
      reportDisplayName:'Calificaciones de Estudiantes',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.historialpromedioprofesor.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'historialpromedioprofesor',
      reportDisplayName:'Historial de Promedio por Centro, Tanda y Profesores',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.historialpromedioestudiante.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'historialpromedioestudiante',
      reportDisplayName:'Historial General de Promedio por Estudiantes',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.calificacioncenter.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'calificacioncenter',
      reportDisplayName:'Calificaciones promedio por Centro, Tanda y Aula',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.cajachica.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'cajachica',
      reportDisplayName:'Caja Chica',
      schema:[
        { name:'registertype',pType:'shape',required:true },
        { name:'initialdate',pType:'date',required:true },
        { name:'enddate',pType:'date',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.combustible.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'combustible',
      reportDisplayName:'Combustible',
      schema:[
        { name:'initialdate',pType:'date',required:true },
        { name:'enddate',pType:'date',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.correspondencia.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'correspondencia',
      reportDisplayName:'Correspondencia',
      schema:[
        { name:'correspondencetype',pType:'shape',required:true },
        { name:'initialdate',pType:'date',required:true },
        { name:'enddate',pType:'date',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.paselista.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'paselista',
      reportDisplayName:'Pase de Lista de Nivel / Admisiones',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:true },
        { name:'province',pType:'shape',required:true },
        { name:'institution',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'classroom',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'attendance',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.activosfijoslocalidad.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'activosfijoslocalidad',
      reportDisplayName:'Activos fijos por Localidad / Administrativo',
      schema:[
        { name:'locality',pType:'shape',required:true },
        { name:'nature',pType:'shape',required:true },
        { name:'typology',pType:'shape',required:true },
        { name:'assetscondition',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'estudiantes_por_centros.create.permission',
      'estudiantes_por_centros.edit.permission',
      'estudiantes_por_centros.delete.permission',
      'estudiantes_por_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.activosfijosnaturaleza.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'activosfijosnaturaleza',
      reportDisplayName:'Activos fijos por Naturaleza / Administrativo',
      schema:[
        { name:'locality',pType:'shape',required:true },
        { name:'nature',pType:'shape',required:true },
        { name:'typology',pType:'shape',required:true },
        { name:'assetscondition',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'informacion_de_centros.create.permission',
      'informacion_de_centros.edit.permission',
      'informacion_de_centros.delete.permission',
      'informacion_de_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.centerInformation.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/report/centerInformation/reportCenterInfo'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.survey.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/survey/infosysSurvey'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyApplication.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/surveyApplication/surveyApplication'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyAdd.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/survey/create/infosysSurveyCreate'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyDetail.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/survey/detail/infosysSurveyDetail'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyEdit.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/survey/edit/infosysSurveyEdit'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyResults.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/surveyAnswers/surveyAnswers'
        )
    )
  },
  {
    permissions: [
      'calendario_academico.create.permission',
      'calendario_academico.edit.permission',
      'calendario_academico.delete.permission',
      'calendario_academico.read.permission'
    ],
    path: urlRoutes.management.infosys.calendar.academicCalendar.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/calendar/academicCalendar/academicCalendar'
        )
    )
  },
  {
    permissions: [
      'calendario_admisiones.create.permission',
      'calendario_admisiones.edit.permission',
      'calendario_admisiones.delete.permission',
      'calendario_admisiones.read.permission'
    ],
    path: urlRoutes.management.infosys.calendar.admissionsCalendar.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/calendar/admissionsCalendar/admissionsCalendar'
        )
    )
  },
  {
    permissions: [
      'agenda_electronica.create.permission',
      'agenda_electronica.edit.permission',
      'agenda_electronica.delete.permission',
      'agenda_electronica.read.permission'
    ],
    path: urlRoutes.management.infosys.agenda.electronicAgenda.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/electronicAgenda/electronicAgenda'
        )
    )
  },
  {
    permissions: [
      'convocatoria_infosys.create.permission',
      'convocatoria_infosys.edit.permission',
      'convocatoria_infosys.delete.permission',
      'convocatoria_infosys.read.permission'
    ],
    path: urlRoutes.management.infosys.report.announcement.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/report/announcement/reportAnnouncement'
        )
    )
  },
]
