import NotificationProvider from "../infrastructure/notification.provider";

class NotificationApplication {
  private readonly notificationProvider?: NotificationProvider;

  constructor(notificationProviderInstance = new NotificationProvider()) {
    this.notificationProvider = notificationProviderInstance;
  }

  async executeGetNotification(data: any) {
    return this.notificationProvider && await this.notificationProvider.getNotification(data);
  }

  async executeDeleteNotification(id: string) {
    return this.notificationProvider && await this.notificationProvider.deleteNotification(id);
  }

  async executeMarkAsRead(id: string) {
    return this.notificationProvider && await this.notificationProvider.markAsRead(id);
  }
}

export default NotificationApplication;
