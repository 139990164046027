import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { User } from '../../domain/types/User';

// Define el tipo para el estado de User, que ahora puede ser null
type UserState = User | null;

const userSlice = createSlice({
  name: 'user',
  initialState: null as UserState, // Usa null como valor inicial
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      // Si tienes un usuario, devuelve el nuevo estado
      return action.payload;
    },
    clearUser: () => {
      // Al limpiar el usuario, devuelves null
      return null;
    },
  }
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
