import { Route, Routes } from 'react-router-dom'

import { publicRoutes } from 'ui/constant/routes/publicRoutes'

export default function PublicPages () {
  return (
    <Routes>
      { publicRoutes.map(routes => (
        <Route key={ routes.path } path={ routes.path } element={ routes.element } />
      )) }
    </Routes>
  )
}
