import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { SessionToken } from 'core/util/stateManager/domain/types/SessionToken';

const initialSessionState: SessionToken = {
  token: '',
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialSessionState,
  reducers: {
    setSessionToken: (state, action: PayloadAction<SessionToken>) => {
      state.token = action.payload.token;
    },
    clearSessionToken: (state) => {
      state.token = '';
    }
  }
});

export const { setSessionToken, clearSessionToken } = sessionSlice.actions;
export default sessionSlice.reducer;
