import type { AxiosInstance } from "axios";
import { setupHttpClient } from "core/util/httpClient/infrastructure/AxiosAdapter";

class HttpClient {
  private static instance: HttpClient;
  private readonly httpAdapter: AxiosInstance;

  private constructor() {
    this.httpAdapter = setupHttpClient();
  }

  static getInstance(): HttpClient {
    if (!HttpClient.instance) {
      HttpClient.instance = new HttpClient();
    }
    return HttpClient.instance;
  }

  getHttpAdapter(): AxiosInstance {
    return this.httpAdapter;
  }
}

export default HttpClient;
