import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../application/slices/UserSlice';
import sessionReducer from '../application/slices/SessionSlice';
import loadingReducer from '../application/slices/LoadingSlice';
import activeReducer from '../application/slices/fixedAssets/active/ActiveSlice';
import brandReducer from '../application/slices/fixedAssets/brand/BrandSlice';
import colorReducer from '../application/slices/fixedAssets/color/ColorSlice';
import modelReducer from '../application/slices/fixedAssets/model/ModelSlice';
import typologyReducer from '../application/slices/fixedAssets/typology/TypologySlice';
import locationReducer from '../application/slices/fixedAssets/location/LocationSlice';
import NatureReducer from '../application/slices/fixedAssets/nature/NatureSlice';
import statusActiveReducer from '../application/slices/fixedAssets/statusActive/StatusActiveSlice';
import ApplicantReducer from '../application/slices/ItemsEvaluationSlice';

const rootReducer = combineReducers({
  user: userReducer,
  token: sessionReducer,
  loading: loadingReducer,
  fixedAssets: combineReducers({
    actives: activeReducer,
    brands: brandReducer,
    colors: colorReducer,
    models: modelReducer,
    typologies: typologyReducer,
    locations: locationReducer,
    natures: NatureReducer,
    statusActives: statusActiveReducer,
  }),
  applicant: ApplicantReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
