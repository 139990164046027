import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const studentRoutes: RouteType[] = [
  {
    permissions: [],
    path: urlRoutes.applicant.profile.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/admissions/announcement/opening/announcementOpening'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.home.url,
    element: lazy(async () => await import('ui/modules/applicant/applicant'))
  },
  {
    permissions: [],
    path: urlRoutes.applicant.newAnnouncement.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/newAnnouncement/applicantNewAnnouncement'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.config.myInformation.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/config/my-information/applicantConfigMyInformation'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.config.letter.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/config/letter-constancy/applicantConfigLetter'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.config.certificado.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/config/certificate/applicantCertificate'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.config.report.url,
    element: lazy(
      async () =>
        await import('ui/modules/applicant/config/report/applicantConfigReport')
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.regulation.url,
    element: lazy(
      async () =>
        await import('ui/modules/applicant/regulations/applicantRegulations')
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.movement.transfer.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/movement/transfer/applicantMovementTransfer'
        )
    )
  },
  {
    permissions: [],
    path: `${urlRoutes.applicant.movement.transfer.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/movement/transfer/create/applicantMovementTransferCreate'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.movement.transfer.create,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/movement/transfer/create/applicantMovementTransferCreate'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.movement.retire.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/movement/retire/applicantMovementRetire'
        )
    )
  },
  {
    permissions: [],
    path: urlRoutes.applicant.movement.retire.create,
    element: lazy(
      async () =>
        await import(
          'ui/modules/applicant/movement/retire/create/applicantMovementRetireCreate'
        )
    )
  }
]
