import { createContext, useReducer, useState } from 'react'
import { authReducer } from './authReducer'
import { useUserSelectors } from 'core/util/stateManager/application/selectors/UserSelector'

export const AuthContext = createContext<any>({})

const initialState = {
  login: false,
  username: null,
  email: null,
  token: null
}

type props = {
  children: JSX.Element | JSX.Element[]
}

export const AuthProvider = ({ children }: props) => {
  const [authState, dispatch] = useReducer(authReducer, initialState)
  const [nonePermissions, setNonePermissions] = useState(false)
  const { userSelector } = useUserSelectors()

  const addAuthFN = (data: any) => {
    dispatch({
      type: 'authUser',
      payload: data
    })
    localStorage.setItem('user', JSON.stringify(data))
  }

  const hasPermissions = (permissions: string[], all: boolean) => {
    if (userSelector) {
      const { 'mescyt/permission': userPermissions } = userSelector
      if (!userPermissions) { setNonePermissions(true); return }
      if (permissions.length === 0) return false
      if (all) {
        return permissions.every((permission) =>
          userPermissions.includes(permission)
        )
      }
      return permissions.some((permission) =>
        userPermissions.includes(permission)
      )
    }
   
  }

  const hasPermission = (searchPermission: string): any => {
    if (userSelector) {
      const { 'mescyt/permission': userPermissions } = userSelector
      if (!userPermissions) { setNonePermissions(true); return }
      return userPermissions.includes(searchPermission)
    }
  
  }

  return (
    <AuthContext.Provider
      value={ {
        authState,
        addAuthFN,
        hasPermissions,
        hasPermission,
        nonePermissions,
        setNonePermissions
      } }
    >
      { children }
    </AuthContext.Provider>
  )
}
