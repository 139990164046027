import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FixedAssets } from '../../../../domain/types/Common';

const initialNatureState: FixedAssets[] = [];

const natureSlice = createSlice({
  name: 'nature',
  initialState: initialNatureState,
  reducers: {
    setNatures: (_state, action: PayloadAction<FixedAssets[]>) => {
      return action.payload;
    },
    clearNatures: () => {
      return initialNatureState;
    }
  }
});

export const { setNatures, clearNatures } = natureSlice.actions;
export default natureSlice.reducer;
