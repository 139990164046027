import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

export const LoadingData = ({ size = 50 }: any) => {
  return (
    <Box sx={ { display: 'flex', justifyContent: 'center' } }>
      <CircularProgress data-testid="loading-indicator" size={ size } />
    </Box>
  )
}
