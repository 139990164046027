import Button from '@mui/material/Button'
import { useContext } from 'react'
import { AuthContext } from 'ui/contexts/auth/authProvider'
import { SweetAlertWarning } from '../Alert/Alert'

type IButtonTextApp = {
  onClick?: any
  children: React.ReactNode
}

export const ButtonTextApp = ({ onClick, children }: IButtonTextApp) => {
  return (
    <Button
      onClick={ onClick }
      variant='text'
      size='small'
      sx={ { textTransform: 'inherit' } }
    >
      { children }
    </Button>
  )
}

type IButtonApp = {
  outlined?: boolean
  disabled?: boolean
  submit?: boolean
  onClick?: () => void
  notFullWidth?: boolean
  children: React.ReactNode
  className?: string
  startIcon?: any
  mb?: any
  mt?: any
  errorColor?: string 
  permissions?: any
}

export const ButtonApp = ({
  outlined,
  disabled,
  submit,
  onClick,
  notFullWidth,
  children,
  className,
  startIcon = null,
  errorColor,
  mb,
  mt,
  permissions = []
}: IButtonApp) => {
  const { hasPermission } = useContext(AuthContext)

  const hasRequiredPermissionsWithAlert = () => {
    return hasPermission(permissions[0])
      ? true
      : (SweetAlertWarning('No tienes acceso'), false)
  }

  const validateClick = () => {
    if (permissions.length > 0) {
      if (hasRequiredPermissionsWithAlert() && typeof onClick === 'function') {
        onClick()
      }
    } else if (typeof onClick === 'function') {
      onClick()
    }
  }

  return (
    <Button
      className={ className }
      size='small'
      color={ errorColor ? 'error' : 'primary' } 
      variant={ outlined ? 'outlined' : 'contained' }
      disabled={ disabled }
      type={ submit ? 'submit' : 'button' }
      onClick={ validateClick }
      fullWidth={ !notFullWidth }
      sx={ {
        paddingX: `${notFullWidth ? '35px' : 'auto'}`,
        marginBottom: `${mb ? '7px' : 'auto'}`,
        marginTop: `${mt ? '18px' : 'auto'}`
      } }
      startIcon={ startIcon }
    >
      { children }
    </Button>
  )
}
