import { Typography } from '@mui/material'

type IProps = {
  children: React.ReactNode
  notBold?: boolean
  notGutterBottom?: boolean
  noWrap?: boolean
  mNone?: boolean
}

export const TextTitle = ({ children }: IProps) => {
  return (
    <Typography variant='h4' sx={ { textTransform: 'uppercase' } }>
      { children }
    </Typography>
  )
}

export const TextSubTitle = ({ children, mNone }: IProps) => {
  return (
    <Typography variant='h6' gutterBottom color='primary' sx={ { textTransform: 'uppercase', fontSize: '16px', marginTop: `${mNone ? '0px' : '20px'}`, fontWeight: 'bold' } }>
      { children }
    </Typography>
  )
}

export const TextBodyTiny = ({ children }: IProps) => {
  return (
    <Typography variant='body2'>
      { children }
    </Typography>
  )
}

export const TextBody = ({ children }: IProps) => {
  return (
    <Typography variant='h6' component='div' sx={ { fontSize: '17px' } }>
      { children }
    </Typography>
  )
}

export const TextBodyBig = ({ children }: IProps) => {
  return (
    <Typography variant='body1'>
      { children }
    </Typography>
  )
}

export const TextErrorLabel = ({ children }: IProps) => {
  return (
    <Typography variant='caption' color='error'>
      { children }
    </Typography>
  )
}

export const TextErrorInput = ({ msg }: any) => {
  return (
    <Typography variant='caption' color='error'>
      { msg && 'Campo requerido' }{ '\u00A0' }
    </Typography>
  )
}

export const TextBodyTinyTitle = ({ children, notBold, notGutterBottom, noWrap }: IProps) => {
  return (
    <Typography noWrap={ !!noWrap } variant='body2' color='primary' sx={ { fontWeight: `${notBold ? 'normal' : 'bold'}` } } gutterBottom={ !notGutterBottom }>
      { children }
    </Typography>
  )
}

export const TextBodyTinyDetails = ({ children }: IProps) => {
  return (
    <Typography variant='body2'>
      { children }
    </Typography>
  )
}
