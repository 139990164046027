import Grid from '@mui/material/Grid'

type IPropsContainer = {
  children: React.ReactNode
  itemsCenter?: boolean
  itemDown?: boolean
  marginY?: boolean
  direction?: any
  justifyContent?: string
  marginX?: number
}

export const GridContainer = ({ children, itemsCenter, itemDown, marginY, direction, marginX, justifyContent }: IPropsContainer) => (
  <Grid
    data-testid="grid-container"
    container
    spacing={ 2 }
    direction={ direction || 'row' || undefined }
    alignItems={ itemsCenter ? 'center' : itemDown ? 'flex-end' : 'flex-start' }
    justifyContent={ justifyContent || 'flex-start' }
    marginY={ marginY ? 1 : 'none' }
    marginX={ marginX || 'none' }
  >
    { children }
  </Grid> 
)

type IPropsItem = {
  children: React.ReactNode
  sm?: number
  md?: number
  lg?: number
  className?: string
}

export const GridItem = ({ children, sm = 12, md = 6, lg = 4, className }: IPropsItem) => (
  <Grid item xs={ 12 } sm={ sm } md={ md } lg={ lg } className={ className } data-testid="grid-item"
  >
    { children }
  </Grid>
)
