import axios from "axios";
import { store } from "core/util/stateManager/infrastructure/Store";

export const setupHttpClient = () => {

  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstance.interceptors.request.use(
    (config: any) => {
      const token = store.getState().token.token
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => error
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401) {
        localStorage.clear();  // Considera actualizar esto si ya no usarás el localStorage para almacenar el token
        window.location.href = "/";
      }
      return await Promise.reject(error);
    }
  );

  return axiosInstance;
};
