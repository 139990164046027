import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const securityRoutes: RouteType[] = [
  {
    permissions: [
      'roles.create.permission',
      'roles.edit.permission',
      'roles.delete.permission',
      'roles.read.permission'
    ],
    path: urlRoutes.security.roles.urlTo,
    element: lazy(async () => await import('ui/modules/security/roles/roles'))
  },

  {
    permissions: [
      'modulos.create.permission',
      'modulos.edit.permission',
      'modulos.delete.permission',
      'modulos.read.permission'
    ],
    path: urlRoutes.security.modules.urlTo,
    element: lazy(
      async () => await import('ui/modules/security/modules/modules')
    )
  },

  {
    permissions: [
      'modulos.create.permission',
      'modulos.edit.permission',
      'modulos.delete.permission',
      'modulos.read.permission'
    ],
    path: urlRoutes.security.modules.add.urlTo,
    element: lazy(
      async () => await import('ui/modules/security/modules/add/addModules')
    )
  },

  {
    permissions: [
      'modulos.create.permission',
      'modulos.edit.permission',
      'modulos.delete.permission',
      'modulos.read.permission'
    ],
    path: urlRoutes.security.modules.edit.urlTo,
    element: lazy(
      async () => await import('ui/modules/security/modules/edit/editModules')
    )
  }
]
