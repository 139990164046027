import { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Avatar from '@mui/material/Avatar'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import Logout from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import { urlRoutes } from 'ui/constant/urls'
import { AuthContext } from 'ui/contexts/auth/authProvider'
import NotificationMenu from 'ui/shared/components/common/NotificationMenu/NotificationMenu'
import NotificationApplication from 'core/notification/application/notification'
import { useAppDispatch } from 'core/util/stateManager/application/Dispatch'
import { clearSessionToken } from 'core/util/stateManager/application/slices/SessionSlice'
import { clearUser } from 'core/util/stateManager/application/slices/UserSlice'

type AccountMenuProps = {
  onLogout?:()=>void,
  onProfileMenuClick?: ()=>void,
  onProfileMenuClose?: ()=>void,
}

export default function AccountMenu ({ onLogout, onProfileMenuClick, onProfileMenuClose }: AccountMenuProps) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();

  const { addAuthFN, authState } = useContext(AuthContext)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(open ? null : event.currentTarget)
    onProfileMenuClick?.();
  }

  const handleClose = () => {
    setAnchorEl(null);
    onProfileMenuClose?.();
  }

  const handleLogout = () => {
    localStorage.clear()
    addAuthFN?.({})
    dispatch(clearSessionToken());
    dispatch(clearUser());
    navigate(urlRoutes.auth.login.urlTo)
    onLogout?.();
  }

  return (
    <>
      <Box sx={ { display: 'flex', alignItems: 'center', textAlign: 'center' } }>
        <NotificationMenu service={ new NotificationApplication() }/>
        <Tooltip title='Configuraciones de la cuenta'>
          <Button
            onClick={ handleClick }
            size='small'
            sx={ { ml: 2 } }
            aria-controls={ open ? 'account-menu2' : undefined }
            aria-haspopup='true'
            aria-expanded={ open ? 'true' : undefined }
          >
            <Avatar sx={ { width: 32, height: 32 } }>{ authState?.auth?.user?.email.substring(0, 1) }</Avatar>
            <div>
              <Typography fontSize={ 12 } fontWeight='bold' align='left' ml={ 1 }>
                { authState?.auth?.user?.email }
              </Typography>
            </div>
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={ anchorEl }
        id='account-menu2'
        open={ open }
        onClose={ () => { handleClose() } }
        PaperProps={ {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0
            }
          }
        } }
        transformOrigin={ { horizontal: 'right', vertical: 'top' } }
        anchorOrigin={ { horizontal: 'right', vertical: 'bottom' } }
      >
        <MenuItem onClick={ handleLogout }>
          <ListItemIcon>
            <Logout fontSize='small' />
          </ListItemIcon>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </>
  )
}
