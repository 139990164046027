import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const academicRoutes: RouteType[] = [
  {
    permissions: [
      'asignacion_de_coordinador.create.permission',
      'asignacion_de_coordinador.edit.permission',
      'asignacion_de_coordinador.delete.permission',
      'asignacion_de_coordinador.read.permission'
    ],
    path: urlRoutes.management.academic.intitutions.asignations.coordinator
      .create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmentsCoordinator/create/createAssignmentsCoordinator'
        )
    )
  },
  {
    permissions: [
      'asignacion_de_coordinador.create.permission',
      'asignacion_de_coordinador.edit.permission',
      'asignacion_de_coordinador.delete.permission',
      'asignacion_de_coordinador.read.permission'
    ],
    path: urlRoutes.management.academic.intitutions.asignations.coordinator.list
      .url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmentsCoordinator/list/listAssignmentsCoordinator'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_coordinador.create.permission',
      'asignacion_de_coordinador.edit.permission',
      'asignacion_de_coordinador.delete.permission',
      'asignacion_de_coordinador.read.permission'
    ],
    path: `${urlRoutes.management.academic.intitutions.asignations.coordinator.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmentsCoordinator/edit/editAssignmentsCoordinator'
        )
    )
  },

  {
    permissions: [
      'creacion_de_aula.create.permission',
      'creacion_de_aula.edit.permission',
      'creacion_de_aula.delete.permission',
      'creacion_de_aula.read.permission'
    ],
    path: urlRoutes.management.academic.seat.classroom.list.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/classroom/list/listClassroom')
    )
  },

  {
    permissions: [
      'creacion_de_aula.create.permission',
      'creacion_de_aula.edit.permission',
      'creacion_de_aula.delete.permission',
      'creacion_de_aula.read.permission'
    ],
    path: urlRoutes.management.academic.seat.classroom.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/classroom/create/createClassroom'
        )
    )
  },

  {
    permissions: [
      'creacion_de_aula.create.permission',
      'creacion_de_aula.edit.permission',
      'creacion_de_aula.delete.permission',
      'creacion_de_aula.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.classroom.list.url}/:id`,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/classroom/edit/editClassroom')
    )
  },

  {
    permissions: [
      'asignacion_de_profesores.create.permission',
      'asignacion_de_profesores.edit.permission',
      'asignacion_de_profesores.delete.permission',
      'asignacion_de_profesores.read.permission'
    ],
    path: urlRoutes.management.academic.seat.teacherClassroom.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmenTeacherToClassroom/list/listAssignmenTeacherToClassroom'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_profesores.create.permission',
      'asignacion_de_profesores.edit.permission',
      'asignacion_de_profesores.delete.permission',
      'asignacion_de_profesores.read.permission'
    ],
    path: urlRoutes.management.academic.seat.teacherClassroom.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmenTeacherToClassroom/create/createassignmenTeacherToClassroom'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_profesores.create.permission',
      'asignacion_de_profesores.edit.permission',
      'asignacion_de_profesores.delete.permission',
      'asignacion_de_profesores.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.teacherClassroom.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmenTeacherToClassroom/edit/editAssignmenTeacherToClassroom'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_estudiantes_a_aula.create.permission',
      'asignacion_de_estudiantes_a_aula.edit.permission',
      'asignacion_de_estudiantes_a_aula.delete.permission',
      'asignacion_de_estudiantes_a_aula.read.permission'
    ],
    path: urlRoutes.management.academic.seat.studentClassroom.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmenStudentToClassroom/list/listAssignmenStudentToClassroom'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_estudiantes_a_aula.create.permission',
      'asignacion_de_estudiantes_a_aula.edit.permission',
      'asignacion_de_estudiantes_a_aula.delete.permission',
      'asignacion_de_estudiantes_a_aula.read.permission'
    ],
    path: urlRoutes.management.academic.seat.studentClassroom.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmenStudentToClassroom/create/createAssignmenStudentToClassroom'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_estudiantes_a_aula.create.permission',
      'asignacion_de_estudiantes_a_aula.edit.permission',
      'asignacion_de_estudiantes_a_aula.delete.permission',
      'asignacion_de_estudiantes_a_aula.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.studentClassroom.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assignmenStudentToClassroom/edit/editAssignmenStudentToClassroom'
        )
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: urlRoutes.management.academic.seat.examen.list.url,
    element: lazy(
      async () => await import('ui/modules/academic/seat/exam/exam')
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: urlRoutes.management.academic.seat.bankExams.list.url,
    element: lazy(
      async () => await import('ui/modules/academic/seat/bankExams/bankExams')
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.bankExams.list.url}/:id`,
    element: lazy(
      async () => await import('ui/modules/academic/seat/bankExams/edit/bankExamsEdit')
    )
  },

  {
    permissions: [
      'estudiantes.create.permission',
      'estudiantes.edit.permission',
      'estudiantes.delete.permission',
      'estudiantes.read.permission'
    ],
    path: urlRoutes.management.academic.seat.student.url,
    element: lazy(
      async () => await import('ui/modules/academic/seat/student/student')
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: urlRoutes.management.academic.seat.examen.narrative.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/exam/narrative/create/createNarrative'
        )
    )
  },
  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.examen.exam.edit.url}/:id`,
    element: lazy(
      async () => await import('ui/modules/academic/seat/exam/edit/examEdit')
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.examen.narrative.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/exam/narrative/edit/editNarrative'
        )
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: urlRoutes.management.academic.seat.examen.examenCategory.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/exam/category/create/examCategoryCreate'
        )
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.examen.examenCategory.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/exam/category/edit/examCategoryEdit'
        )
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: urlRoutes.management.academic.seat.examen.question.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/exam/question/create/questionCreate'
        )
    )
  },

  {
    permissions: [
      'examenes.create.permission',
      'examenes.edit.permission',
      'examenes.delete.permission',
      'examenes.read.permission'
    ],
    path: urlRoutes.management.academic.seat.examen.exam.create.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/exam/create/examCreate')
    )
  },

  {
    permissions: [
      'pase_de_lista_de_profesor.create.permission',
      'pase_de_lista_de_profesor.edit.permission',
      'pase_de_lista_de_profesor.delete.permission',
      'pase_de_lista_de_profesor.read.permission'
    ],
    path: urlRoutes.management.academic.seat.teacherPassList.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/teacherPassList/teacherPassList')
    )
  },

  {
    permissions: [
      'registro_de_calificaciones.create.permission',
      'registro_de_calificaciones.edit.permission',
      'registro_de_calificaciones.delete.permission',
      'registro_de_calificaciones.read.permission'
    ],
    path: urlRoutes.management.academic.seat.registerQualifications.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/registerQualifications/registerQualifications'
        )
    )
  },

  {
    permissions: [
      'solicitud_calculo_de_calificaciones.create.permission',
      'solicitud_calculo_de_calificaciones.edit.permission',
      'solicitud_calculo_de_calificaciones.delete.permission',
      'solicitud_calculo_de_calificaciones.read.permission'
    ],
    path: urlRoutes.management.academic.seat.gradeCalculationRequest.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/gradeCalculationRequest/list/listGradeCalculation'
        )
    )
  },
  {
    permissions: [
      'solicitud_calculo_de_calificaciones.create.permission',
      'solicitud_calculo_de_calificaciones.edit.permission',
      'solicitud_calculo_de_calificaciones.delete.permission',
      'solicitud_calculo_de_calificaciones.read.permission'
    ],
    path: urlRoutes.management.academic.seat.gradeCalculationRequest.create.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/gradeCalculationRequest/create/createGradeCalculation')
    )
  },
  {
    permissions: [
      'solicitud_calculo_de_calificaciones.create.permission',
      'solicitud_calculo_de_calificaciones.edit.permission',
      'solicitud_calculo_de_calificaciones.delete.permission',
      'solicitud_calculo_de_calificaciones.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.gradeCalculationRequest.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/gradeCalculationRequest/edit/editGradeCalculation'
        )
    )
  },

  {
    permissions: [
      'solicitud_de_inicio_de_nivel.create.permission',
      'solicitud_de_inicio_de_nivel.edit.permission',
      'solicitud_de_inicio_de_nivel.delete.permission',
      'solicitud_de_inicio_de_nivel.read.permission'
    ],
    path: urlRoutes.management.academic.seat.startLevelRequest.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/startLevelRequest/list/listStartLevelRequest'
        )
    )
  },
  {
    permissions: [
      'solicitud_de_inicio_de_nivel.create.permission',
      'solicitud_de_inicio_de_nivel.edit.permission',
      'solicitud_de_inicio_de_nivel.delete.permission',
      'solicitud_de_inicio_de_nivel.read.permission'
    ],
    path: urlRoutes.management.academic.seat.startLevelRequest.create.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/startLevelRequest/create/createStartLevelRequest')
    )
  },
  {
    permissions: [
      'solicitud_de_inicio_de_nivel.create.permission',
      'solicitud_de_inicio_de_nivel.edit.permission',
      'solicitud_de_inicio_de_nivel.delete.permission',
      'solicitud_de_inicio_de_nivel.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.startLevelRequest.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/startLevelRequest/edit/editStartLevelRequest'
        )
    )
  },

  // Solicitud descargo de activos fijos

  {
    permissions: [
      'solicitud_activo_fijo.create.permission',
      'solicitud_activo_fijo.edit.permission',
      'solicitud_activo_fijo.delete.permission',
      'solicitud_activo_fijo.read.permission'
    ],
    path: urlRoutes.management.academic.seat.dischargeFixedAssets.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/dischargeFixedAssets/list/dischargeFixedAssets'
        )
    )
  },
  {
    permissions: [
      'solicitud_activo_fijo.create.permission',
      'solicitud_activo_fijo.edit.permission',
      'solicitud_activo_fijo.delete.permission',
      'solicitud_activo_fijo.read.permission'
    ],
    path: urlRoutes.management.academic.seat.dischargeFixedAssets.create.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/dischargeFixedAssets/create/createDischargeFixedAssets')
    )
  },
  {
    permissions: [
      'solicitud_activo_fijo.create.permission',
      'solicitud_activo_fijo.edit.permission',
      'solicitud_activo_fijo.delete.permission',
      'solicitud_activo_fijo.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.dischargeFixedAssets.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/dischargeFixedAssets/edit/editDischargeFixedAssets'
        )
    )
  },
  {
    permissions: [
      'solicitud_mantenimiento_planta_fisica.create.permission',
      'solicitud_mantenimiento_planta_fisica.edit.permission',
      'solicitud_mantenimiento_planta_fisica.delete.permission',
      'solicitud_mantenimiento_planta_fisica.read.permission'
    ],
    path: urlRoutes.management.academic.seat.physicalPlantMaintenance.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/physicalPlantMaintenance/list/physicalPlantMaintenance'
        )
    )
  },
  {
    permissions: [
      'solicitud_mantenimiento_planta_fisica.create.permission',
      'solicitud_mantenimiento_planta_fisica.edit.permission',
      'solicitud_mantenimiento_planta_fisica.delete.permission',
      'solicitud_mantenimiento_planta_fisica.read.permission'
    ],
    path: urlRoutes.management.academic.seat.physicalPlantMaintenance.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/physicalPlantMaintenance/create/createPhysicalPlantMaintenance'
        )
    )
  },
  {
    permissions: [
      'solicitud_mantenimiento_planta_fisica.create.permission',
      'solicitud_mantenimiento_planta_fisica.edit.permission',
      'solicitud_mantenimiento_planta_fisica.delete.permission',
      'solicitud_mantenimiento_planta_fisica.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.physicalPlantMaintenance.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/physicalPlantMaintenance/edit/editPhysicalPlantMaintenance'
        )
    )
  },
  {
    permissions: [
      'solicitud_mantenimiento_laboratorio.create.permission',
      'solicitud_mantenimiento_laboratorio.edit.permission',
      'solicitud_mantenimiento_laboratorio.delete.permission',
      'solicitud_mantenimiento_laboratorio.read.permission'
    ],
    path: urlRoutes.management.academic.seat.laboratoryMaintenanceRequest.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/laboratoryMaintenanceRequest/list/laboratoryMaintenanceRequest'
        )
    )
  },
  {
    permissions: [
      'solicitud_mantenimiento_laboratorio.create.permission',
      'solicitud_mantenimiento_laboratorio.edit.permission',
      'solicitud_mantenimiento_laboratorio.delete.permission',
      'solicitud_mantenimiento_laboratorio.read.permission'
    ],
    path: urlRoutes.management.academic.seat.laboratoryMaintenanceRequest.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/laboratoryMaintenanceRequest/create/createLaboratoryMaintenanceRequest'
        )
    )
  },
  {
    permissions: [
      'solicitud_mantenimiento_laboratorio.create.permission',
      'solicitud_mantenimiento_laboratorio.edit.permission',
      'solicitud_mantenimiento_laboratorio.delete.permission',
      'solicitud_mantenimiento_laboratorio.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.laboratoryMaintenanceRequest.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/laboratoryMaintenanceRequest/edit/editLaboratoryMaintenanceRequest'
        )
    )
  },

  {
    permissions: [
      'soporte_tecnico.create.permission',
      'soporte_tecnico.edit.permission',
      'soporte_tecnico.delete.permission',
      'soporte_tecnico.read.permission'
    ],
    path: urlRoutes.management.academic.seat.technicalSupport.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/technicalSupport/list/technicalSupport'
        )
    )
  },
  {
    permissions: [
      'soporte_tecnico.create.permission',
      'soporte_tecnico.edit.permission',
      'soporte_tecnico.delete.permission',
      'soporte_tecnico.read.permission'
    ],
    path: urlRoutes.management.academic.seat.technicalSupport.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/technicalSupport/create/createTechnicalSupport'
        )
    )
  },
  {
    permissions: [
      'soporte_tecnico.create.permission',
      'soporte_tecnico.edit.permission',
      'soporte_tecnico.delete.permission',
      'soporte_tecnico.read.permission'
    ],
    path: `${urlRoutes.management.academic.seat.technicalSupport.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/technicalSupport/edit/editTechnicalSupport'
        )
    )
  },

  {
    permissions: [
      'asignacion_libros_estudiantes.create.permission',
      'asignacion_libros_estudiantes.edit.permission',
      'asignacion_libros_estudiantes.delete.permission',
      'asignacion_libros_estudiantes.read.permission'
    ],
    path: urlRoutes.management.academic.seat.assigningBooksStudents.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/assigningBooksStudents/list/assigningBooksStudents'
        )
    )
  },

  // Calificaciones
  {
    permissions: [
      'calificaciones.create.permission',
      'calificaciones.edit.permission',
      'calificaciones.delete.permission',
      'calificaciones.read.permission'
    ],
    path: urlRoutes.management.academic.seat.qualifications.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/qualifications/qualifications')
    )
  },

  {
    permissions: [
      'tardanza_y_excusas.create.permission',
      'tardanza_y_excusas.edit.permission',
      'tardanza_y_excusas.delete.permission',
      'tardanza_y_excusas.read.permission'
    ],
    path: urlRoutes.management.academic.seat.tardiesExcuses.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/seat/tardiesExcuses/tardiesExcuses')
    )
  },

  {
    permissions: [
      'registro_y_control_asistencia_profesor.create.permission',
      'registro_y_control_asistencia_profesor.edit.permission',
      'registro_y_control_asistencia_profesor.delete.permission',
      'registro_y_control_asistencia_profesor.read.permission'
    ],
    path: urlRoutes.management.academic.seat
      .registrationTeacherAttendanceControl.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/seat/registrationTeacherAttendanceControl/registrationTeacherAttendanceControl'
        )
    )
  },

  {
    permissions: [
      'movimientos.create.permission',
      'movimientos.edit.permission',
      'movimientos.delete.permission',
      'movimientos.read.permission'
    ],
    path: urlRoutes.management.academic.intitutions.movements.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/intitutions/movements/movements')
    )
  },

  {
    permissions: [
      'movimientos.create.permission',
      'movimientos.edit.permission',
      'movimientos.delete.permission',
      'movimientos.read.permission'
    ],
    path: `${urlRoutes.management.academic.intitutions.movements.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/intitutions/movements/transfer/transfer'
        )
    )
  },
  {
    permissions: [
    ],
    path: `${urlRoutes.management.academic.intitutions.movements.editWithdrawal.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/intitutions/movements/withdrawals/withdrawals'
        )
    )
  },
  {
    permissions: [
      'movimientos.create.permission',
      'movimientos.edit.permission',
      'movimientos.delete.permission',
      'movimientos.read.permission'
    ],
    path: `${urlRoutes.management.academic.intitutions.movements.manualTransfer}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/intitutions/movements/manualTransfer/manualTransfer'
        )
    )
  },

  {
    permissions: [
      'estado_graduado.create.permission',
      'estado_graduado.edit.permission',
      'estado_graduado.delete.permission',
      'estado_graduado.read.permission'
    ],
    path: urlRoutes.management.academic.intitutions.graduate.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/intitutions/graduate/graduate')
    )
  },

  {
    permissions: [
      'registro_de_capacitacion.create.permission',
      'registro_de_capacitacion.edit.permission',
      'registro_de_capacitacion.delete.permission',
      'registro_de_capacitacion.read.permission'
    ],
    path: urlRoutes.management.academic.training.register.list.url,
    element: lazy(
      async () =>
        await import('ui/modules/academic/training/register/list/trainingList')
    )
  },

  {
    permissions: [
      'registro_de_capacitacion.create.permission',
      'registro_de_capacitacion.edit.permission',
      'registro_de_capacitacion.delete.permission',
      'registro_de_capacitacion.read.permission'
    ],
    path: urlRoutes.management.academic.training.register.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/training/register/create/trainingCreate'
        )
    )
  },

  {
    permissions: [
      'registro_de_capacitacion.create.permission',
      'registro_de_capacitacion.edit.permission',
      'registro_de_capacitacion.delete.permission',
      'registro_de_capacitacion.read.permission'
    ],
    path: `${urlRoutes.management.academic.training.register.list.url}/:id`,
    element: lazy(
      async () =>
        await import('ui/modules/academic/training/register/edit/trainingEdit')
    )
  },

  {
    permissions: [
      'creacion_de_evento.create.permission',
      'creacion_de_evento.edit.permission',
      'creacion_de_evento.delete.permission',
      'creacion_de_evento.read.permission'
    ],
    path: urlRoutes.management.academic.training.event.list.url,
    element: lazy(
      async () => await import('ui/modules/academic/training/event/listEvent')
    )
  },

  {
    permissions: [
      'creacion_de_evento.create.permission',
      'creacion_de_evento.edit.permission',
      'creacion_de_evento.delete.permission',
      'creacion_de_evento.read.permission'
    ],
    path: urlRoutes.management.academic.training.event.create.url,
    element: lazy(
      async () => await import('ui/modules/academic/training/event/createEvent')
    )
  },

  {
    permissions: [
      'creacion_de_evento.create.permission',
      'creacion_de_evento.edit.permission',
      'creacion_de_evento.delete.permission',
      'creacion_de_evento.read.permission'
    ],
    path: `${urlRoutes.management.academic.training.event.list.url}/:id`,
    element: lazy(
      async () => await import('ui/modules/academic/training/event/editEvent')
    )
  },

  {
    permissions: [
      'apertura_docente.create.permission',
      'apertura_docente.edit.permission',
      'apertura_docente.delete.permission',
      'apertura_docente.read.permission'
    ],
    path: urlRoutes.management.academic.evaluation.teachingCreation.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/evaluation/teachingCreation/teachingCreationList'
        )
    )
  },

  {
    permissions: [
      'creacion_de_evento.create.permission',
      'creacion_de_evento.edit.permission',
      'creacion_de_evento.delete.permission',
      'creacion_de_evento.read.permission'
    ],
    path: `${urlRoutes.management.academic.evaluation.teachingCreation.list.url}/:id`,
    element: lazy(
      async () => await import('ui/modules/academic/evaluation/teachingUpdate/teachingUpdate')
    )
  },

  {
    permissions: [
      'apertura_docente.create.permission',
      'apertura_docente.edit.permission',
      'apertura_docente.delete.permission',
      'apertura_docente.read.permission'
    ],
    path: urlRoutes.management.academic.evaluation.teachingCreation.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/evaluation/teachingCreation/teachingCreation'
        )
    )
  },

  {
    permissions: [
      'estatus_de_apertura_docente.create.permission',
      'estatus_de_apertura_docente.edit.permission',
      'estatus_de_apertura_docente.delete.permission',
      'estatus_de_apertura_docente.read.permission'
    ],
    path: urlRoutes.management.academic.evaluation.teachingStatus.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/evaluation/teachingStatus/list/teachingStatusList'
        )
    )
  },

  {
    permissions: [
      'solicitud_de_transferencia.create.permission',
      'solicitud_de_transferencia.edit.permission',
      'solicitud_de_transferencia.delete.permission',
      'solicitud_de_transferencia.read.permission'
    ],
    path: urlRoutes.management.academic.intitutions.movements.newTransferRequest
      .url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/intitutions/movements/transfer/transfer'
        )
    )
  },

  {
    permissions: [
      'solicitud_de_transferencia.create.permission',
      'solicitud_de_transferencia.edit.permission',
      'solicitud_de_transferencia.delete.permission',
      'solicitud_de_transferencia.read.permission'
    ],
    path: urlRoutes.management.academic.intitutions.movements
      .editTransferRequest.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/intitutions/movements/manualTransfer/manualTransfer'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_supervisor.create.permission',
      'asignacion_de_supervisor.edit.permission',
      'asignacion_de_supervisor.delete.permission',
      'asignacion_de_supervisor.read.permission'
    ],
    path: urlRoutes.management.academic.supervision.supervisor.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/supervisor/list/supervisorList'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_supervisor.create.permission',
      'asignacion_de_supervisor.edit.permission',
      'asignacion_de_supervisor.delete.permission',
      'asignacion_de_supervisor.read.permission'
    ],
    path: urlRoutes.management.academic.supervision.supervisor.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/supervisor/create/supervisorCreate'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_supervisor.create.permission',
      'asignacion_de_supervisor.edit.permission',
      'asignacion_de_supervisor.delete.permission',
      'asignacion_de_supervisor.read.permission'
    ],
    path: `${urlRoutes.management.academic.supervision.supervisor.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/supervisor/edit/supervisorEdit'
        )
    )
  },

  {
    permissions: [
      'creacion_de_preguntas.create.permission',
      'creacion_de_preguntas.edit.permission',
      'creacion_de_preguntas.delete.permission',
      'creacion_de_preguntas.read.permission'
    ],
    path: urlRoutes.management.academic.supervision.question.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/question/list/SupervisionQuestionList'
        )
    )
  },

  {
    permissions: [
      'creacion_de_preguntas.create.permission',
      'creacion_de_preguntas.edit.permission',
      'creacion_de_preguntas.delete.permission',
      'creacion_de_preguntas.read.permission'
    ],
    path: urlRoutes.management.academic.supervision.question.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/question/create/supervisionQuestionCreate'
        )
    )
  },

  {
    permissions: [
      'creacion_de_preguntas.create.permission',
      'creacion_de_preguntas.edit.permission',
      'creacion_de_preguntas.delete.permission',
      'creacion_de_preguntas.read.permission'
    ],
    path: `${urlRoutes.management.academic.supervision.question.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/question/edit/supervisionQuestionEdit'
        )
    )
  },

  {
    permissions: [
      'configuracion_de_visitas.create.permission',
      'configuracion_de_visitas.edit.permission',
      'configuracion_de_visitas.delete.permission',
      'configuracion_de_visitas.read.permission'
    ],
    path: urlRoutes.management.academic.supervision.questionBank.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/questionBank/list/questionBankList'
        )
    )
  },

  {
    permissions: [
      'configuracion_de_visitas.create.permission',
      'configuracion_de_visitas.edit.permission',
      'configuracion_de_visitas.delete.permission',
      'configuracion_de_visitas.read.permission'
    ],
    path: urlRoutes.management.academic.supervision.questionBank.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/questionBank/create/questionBankCreate'
        )
    )
  },

  {
    permissions: [
      'configuracion_de_visitas.create.permission',
      'configuracion_de_visitas.edit.permission',
      'configuracion_de_visitas.delete.permission',
      'configuracion_de_visitas.read.permission'
    ],
    path: `${urlRoutes.management.academic.supervision.questionBank.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/questionBank/edit/questionBankEdit'
        )
    )
  },

  {
    permissions: [
      'registro_de_visitas_centro.create.permission',
      'registro_de_visitas_centro.edit.permission',
      'registro_de_visitas_centro.delete.permission',
      'registro_de_visitas_centro.read.permission'
    ],
    path: urlRoutes.management.academic.supervision.registerVisit.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/registerVisit/list/registerVisitList'
        )
    )
  },

  {
    permissions: [
      'registro_de_visitas_centro.create.permission',
      'registro_de_visitas_centro.edit.permission',
      'registro_de_visitas_centro.delete.permission',
      'registro_de_visitas_centro.read.permission'
    ],
    path: urlRoutes.management.academic.supervision.registerVisit.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/registerVisit/create/registerVisitCreate'
        )
    )
  },
  {
    permissions: [
      'registro_de_visitas_centro.create.permission',
      'registro_de_visitas_centro.edit.permission',
      'registro_de_visitas_centro.delete.permission',
      'registro_de_visitas_centro.read.permission'
    ],
    path: `${urlRoutes.management.academic.supervision.registerVisit.edit.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/academic/supervision/registerVisit/edit/registerVisitEdit'
        )
    )
  }
]
