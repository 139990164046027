import { urlRoutes } from 'ui/constant/urls'
import { Navigate, Outlet } from 'react-router-dom'

type IProps = {
  isLoggedIn: boolean
}

const PrivatedRoutes = ({ isLoggedIn }: IProps) => {
  return isLoggedIn ? <Outlet /> : <Navigate to={ urlRoutes.auth.login.urlTo } />
}

export default PrivatedRoutes
