import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import { AuthProvider } from 'ui/contexts/auth/authProvider'

import Routers from 'ui/routers/router'
import { theme } from 'ui/shared/theme/theme'
import { Provider } from 'react-redux'
import { store, persistor } from 'core/util/stateManager/infrastructure/Store'
import { PersistGate } from 'redux-persist/integration/react';

function App () {
  
  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <AuthProvider>
          <ThemeProvider theme={ theme }>
            <CssBaseline />
            <Routers />
          </ThemeProvider>
        </AuthProvider>
      </PersistGate>

    </Provider>
  )
}

export default App
