import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const admissionsRoutes: RouteType[] = [
  {
    permissions: [
      'convocatoria_nivel.create.permission',
      'convocatoria_nivel.edit.permission',
      'convocatoria_nivel.delete.permission',
      'convocatoria_nivel.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.level.list.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/announcement/level/list/levelList')
    )
  },

  {
    permissions: [
      'validacion.create.permission',
      'validacion.edit.permission',
      'validacion.delete.permission',
      'validacion.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.validation.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/validation/list/technicalValidationList'
        )
    )
  },
  {
    permissions: [
      'validacion_docente.create.permission',
      'validacion_docente.edit.permission',
      'validacion_docente.delete.permission',
      'validacion_docente.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.validationTeacher.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/validationTeacher/list/teacherValidationList'
        )
    )
  },
  {
    permissions: [
      'cambio_estado.create.permission',
      'cambio_estado.edit.permission',
      'cambio_estado.delete.permission',
      'cambio_estado.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.changeStatus.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/changeStatus/changeStatus'
        )
    )
  },

  {
    permissions: [
      'idioma.create.permission',
      'idioma.edit.permission',
      'idioma.delete.permission',
      'idioma.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.language.list.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/language/list/languageList')
    )
  },
  {
    permissions: [
      'idioma.create.permission',
      'idioma.edit.permission',
      'idioma.delete.permission',
      'idioma.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.language.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/language/create/languageCreate'
        )
    )
  },
  {
    permissions: [
      'idioma.create.permission',
      'idioma.edit.permission',
      'idioma.delete.permission',
      'idioma.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.language.list.url}/:id`,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/language/edit/languageEdit')
    )
  },

  {
    permissions: [
      'motivo_de_transferencia.create.permission',
      'motivo_de_transferencia.edit.permission',
      'motivo_de_transferencia.delete.permission',
      'motivo_de_transferencia.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.transferReason.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/transferReason/list/transferReasonList'
        )
    )
  },
  {
    permissions: [
      'motivo_de_transferencia.create.permission',
      'motivo_de_transferencia.edit.permission',
      'motivo_de_transferencia.delete.permission',
      'motivo_de_transferencia.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.transferReason.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/transferReason/create/transferReasonCreate'
        )
    )
  },
  {
    permissions: [
      'motivo_de_transferencia.create.permission',
      'motivo_de_transferencia.edit.permission',
      'motivo_de_transferencia.delete.permission',
      'motivo_de_transferencia.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.transferReason.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/transferReason/edit/transferReasonEdit'
        )
    )
  },

  {
    permissions: [
      'centros.create.permission',
      'centros.edit.permission',
      'centros.delete.permission',
      'centros.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.seat.url}/:tab`,
    element: lazy(
      async () => await import('ui/modules/admissions/setting/seat/seat')
    )
  },
  {
    permissions: [
      'centros.create.permission',
      'centros.edit.permission',
      'centros.delete.permission',
      'centros.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.seat.institution.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/seat/institution/create/seatInstitutionCreate'
        )
    )
  },
  {
    permissions: [
      'centros.create.permission',
      'centros.edit.permission',
      'centros.delete.permission',
      'centros.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.seat.institution.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/seat/institution/edit/seatInstitutionEdit'
        )
    )
  },
  {
    permissions: [
      'centros.create.permission',
      'centros.edit.permission',
      'centros.delete.permission',
      'centros.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.seat.enclosure.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/seat/enclosure/create/seatEnclosureCreate'
        )
    )
  },
  {
    permissions: [
      'centros.create.permission',
      'centros.edit.permission',
      'centros.delete.permission',
      'centros.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.seat.enclosure.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/seat/enclosure/edit/seatEnclosureEdit'
        )
    )
  },

  {
    permissions: [
      'modalidad.create.permission',
      'modalidad.edit.permission',
      'modalidad.delete.permission',
      'modalidad.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.modality.list.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/modality/list/modalityList')
    )
  },
  {
    permissions: [
      'modalidad.create.permission',
      'modalidad.edit.permission',
      'modalidad.delete.permission',
      'modalidad.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.modality.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/modality/create/modalityCreate'
        )
    )
  },
  {
    permissions: [
      'modalidad.create.permission',
      'modalidad.edit.permission',
      'modalidad.delete.permission',
      'modalidad.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.modality.list.url}/:id`,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/modality/edit/modalityEdit')
    )
  },

  {
    permissions: [
      'convocatoria_nivel.create.permission',
      'convocatoria_nivel.edit.permission',
      'convocatoria_nivel.delete.permission',
      'convocatoria_nivel.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.levelAnnoucement.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/levelAnnoucement/list/levelAnnoucementList'
        )
    )
  },
  {
    permissions: [
      'convocatoria_nivel.create.permission',
      'convocatoria_nivel.edit.permission',
      'convocatoria_nivel.delete.permission',
      'convocatoria_nivel.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.levelAnnoucement.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/levelAnnoucement/create/levelAnnoucementCreate'
        )
    )
  },
  {
    permissions: [
      'convocatoria_nivel.create.permission',
      'convocatoria_nivel.edit.permission',
      'convocatoria_nivel.delete.permission',
      'convocatoria_nivel.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.levelAnnoucement.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/levelAnnoucement/edit/levelAnnoucementEdit'
        )
    )
  },
  {
    permissions: [
      'convocatoria_nivel.create.permission',
      'convocatoria_nivel.edit.permission',
      'convocatoria_nivel.delete.permission',
      'convocatoria_nivel.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.levelAnnoucement.bulkEdit.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/levelAnnoucement/bulkEdit/bulkEdit'
        )
    )
  },

  {
    permissions: [
      'creacion_de_nivel.create.permission',
      'creacion_de_nivel.edit.permission',
      'creacion_de_nivel.delete.permission',
      'creacion_de_nivel.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.level.list.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/level/list/levelList')
    )
  },
  {
    permissions: [
      'creacion_de_nivel.create.permission',
      'creacion_de_nivel.edit.permission',
      'creacion_de_nivel.delete.permission',
      'creacion_de_nivel.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.level.create.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/level/create/levelCreate')
    )
  },
  {
    permissions: [
      'creacion_de_nivel.create.permission',
      'creacion_de_nivel.edit.permission',
      'creacion_de_nivel.delete.permission',
      'creacion_de_nivel.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.level.edit.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/level/edit/levelEdit')
    )
  },

  {
    permissions: [
      'items_de_evaluacion.create.permission',
      'items_de_evaluacion.edit.permission',
      'items_de_evaluacion.delete.permission',
      'items_de_evaluacion.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.evaluation.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/evaluation/list/evaluationList'
        )
    )
  },
  {
    permissions: [
      'items_de_evaluacion.create.permission',
      'items_de_evaluacion.edit.permission',
      'items_de_evaluacion.delete.permission',
      'items_de_evaluacion.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.evaluation.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/evaluation/create/evaluationCreate'
        )
    )
  },
  {
    permissions: [
      'items_de_evaluacion.create.permission',
      'items_de_evaluacion.edit.permission',
      'items_de_evaluacion.delete.permission',
      'items_de_evaluacion.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.evaluation.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/evaluation/edit/evaluationEdit'
        )
    )
  },
  {
    permissions: [
      'items_de_evaluacion.create.permission',
      'items_de_evaluacion.edit.permission',
      'items_de_evaluacion.delete.permission',
      'items_de_evaluacion.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.evaluation.bulkEdit.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/evaluation/bulkEdit/bulkEdit'
        )
    )
  },

  {
    permissions: [
      'seleccion_de_cupos.create.permission',
      'seleccion_de_cupos.edit.permission',
      'seleccion_de_cupos.delete.permission',
      'seleccion_de_cupos.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.quota.list.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/quota/list/quotaList')
    )
  },
  {
    permissions: [
      'seleccion_de_cupos.create.permission',
      'seleccion_de_cupos.edit.permission',
      'seleccion_de_cupos.delete.permission',
      'seleccion_de_cupos.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.quota.create.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/setting/quota/create/quotaCreate')
    )
  },

  {
    permissions: [
      'convocatoria.create.permission',
      'convocatoria.edit.permission',
      'convocatoria.delete.permission',
      'convocatoria.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.announcement.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/announcement/list/announcementList'
        )
    )
  },
  {
    permissions: [
      'convocatoria.create.permission',
      'convocatoria.edit.permission',
      'convocatoria.delete.permission',
      'convocatoria.read.permission'
    ],
    path: urlRoutes.management.admissions.setting.announcement.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/announcement/create/announcementCreate'
        )
    )
  },
  {
    permissions: [
      'convocatoria.create.permission',
      'convocatoria.edit.permission',
      'convocatoria.delete.permission',
      'convocatoria.read.permission'
    ],
    path: `${urlRoutes.management.admissions.setting.announcement.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/setting/announcement/edit/announcementEdit'
        )
    )
  },

  {
    permissions: [
      'estatus_de_convocatoria.create.permission',
      'estatus_de_convocatoria.edit.permission',
      'estatus_de_convocatoria.delete.permission',
      'estatus_de_convocatoria.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.status.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/status/announcementStatus'
        )
    )
  },
  {
    permissions: [
      'estatus_de_aplicante.create.permission',
      'estatus_de_aplicante.edit.permission',
      'estatus_de_aplicante.delete.permission',
      'estatus_de_aplicante.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.statusApplicant.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/statusApplicant/list/statusApplicantList'
        )
    )
  },

  {
    permissions: [
      'validacion.create.permission',
      'validacion.edit.permission',
      'validacion.delete.permission',
      'validacion.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.students.url,
    element: lazy(
      async () =>
        await import('ui/modules/admissions/announcement/students/students')
    )
  },
  {
    permissions: [
      'solicitante.create.permission',
      'solicitante.edit.permission',
      'solicitante.delete.permission',
      'solicitante.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.students.add.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/students/list/studentList'
        )
    )
  },
  {
    permissions: [
      'solicitante.create.permission',
      'solicitante.edit.permission',
      'solicitante.delete.permission',
      'solicitante.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.students.add.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/students/create/studentsCreate'
        )
    )
  },
  {
    permissions: [
      'solicitante.create.permission',
      'solicitante.edit.permission',
      'solicitante.delete.permission',
      'solicitante.read.permission'
    ],
    path: `${urlRoutes.management.admissions.announcement.students.detail.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/students/detail/studentsDetail'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_profesores.create.permission',
      'asignacion_de_profesores.edit.permission',
      'asignacion_de_profesores.delete.permission',
      'asignacion_de_profesores.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.teacher.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/teacher/list/teacherList'
        )
    )
  },
  {
    permissions: [
      'asignacion_de_profesores.create.permission',
      'asignacion_de_profesores.edit.permission',
      'asignacion_de_profesores.delete.permission',
      'asignacion_de_profesores.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.teacher.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/teacher/create/createTeacher'
        )
    )
  },
  {
    permissions: [
      'asignacion_de_profesores.create.permission',
      'asignacion_de_profesores.edit.permission',
      'asignacion_de_profesores.delete.permission',
      'asignacion_de_profesores.read.permission'
    ],
    path: `${urlRoutes.management.admissions.announcement.teacher.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/teacher/edit/editTeacher'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_profesor_encargado.create.permission',
      'asignacion_de_profesor_encargado.edit.permission',
      'asignacion_de_profesor_encargado.delete.permission',
      'asignacion_de_profesor_encargado.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.manager.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/assignmentsManager/list/assigmentsManagerList'
        )
    )
  },
  {
    permissions: [
      'asignacion_de_profesor_encargado.create.permission',
      'asignacion_de_profesor_encargado.edit.permission',
      'asignacion_de_profesor_encargado.delete.permission',
      'asignacion_de_profesor_encargado.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.manager.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/assignmentsManager/create/assigmentsManagerCreate'
        )
    )
  },
  {
    permissions: [
      'asignacion_de_profesor_encargado.create.permission',
      'asignacion_de_profesor_encargado.edit.permission',
      'asignacion_de_profesor_encargado.delete.permission',
      'asignacion_de_profesor_encargado.read.permission'
    ],
    path: `${urlRoutes.management.admissions.announcement.manager.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/assignmentsManager/edit/assigmentsManagerEdit'
        )
    )
  },

  {
    permissions: [
      'pase_de_lista_de_nivel.create.permission',
      'pase_de_lista_de_nivel.edit.permission',
      'pase_de_lista_de_nivel.delete.permission',
      'pase_de_lista_de_nivel.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.passListLevel.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/passListLevel/passListLevel'
        )
    )
  },

  {
    permissions: [
      'asignacion_de_soporte_de_admisiones.create.permission',
      'asignacion_de_soporte_de_admisiones.edit.permission',
      'asignacion_de_soporte_de_admisiones.delete.permission',
      'asignacion_de_soporte_de_admisiones.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.technical.list.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/technical/list/technicalList'
        )
    )
  },
  {
    permissions: [
      'asignacion_de_soporte_de_admisiones.create.permission',
      'asignacion_de_soporte_de_admisiones.edit.permission',
      'asignacion_de_soporte_de_admisiones.delete.permission',
      'asignacion_de_soporte_de_admisiones.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.technical.create.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/technical/create/technicalCreate'
        )
    )
  },
  {
    permissions: [
      'asignacion_de_soporte_de_admisiones.create.permission',
      'asignacion_de_soporte_de_admisiones.edit.permission',
      'asignacion_de_soporte_de_admisiones.delete.permission',
      'asignacion_de_soporte_de_admisiones.read.permission'
    ],
    path: `${urlRoutes.management.admissions.announcement.technical.list.url}/:id`,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/technical/edit/technicalEdit'
        )
    )
  },

  {
    permissions: [
      'solicitud_calculo_de_calificaciones.create.permission',
      'solicitud_calculo_de_calificaciones.edit.permission',
      'solicitud_calculo_de_calificaciones.delete.permission',
      'solicitud_calculo_de_calificaciones.read.permission'
    ],
    path: urlRoutes.management.admissions.announcement.qualifications.url,
    element: lazy(
      async () =>
        await import(
          'ui/modules/admissions/announcement/gradeCalculation/gradeCalculation'
        )
    )
  }
]
