import { configureStore } from '@reduxjs/toolkit';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from './RootReducer';

// Creamos un encriptador personalizado
const encryptor = encryptTransform({
  secretKey: process.env.SECRET_KEY_PERSIST || 'my-super-secret-key',
  onError: (error) => {
    // Se captura error de encript
    console.error('%cStore.ts line:12 object', 'color: #007acc;', error);
  },
});

// Definimos el tipo de estado de nuestra aplicación
type ApplicationState = ReturnType<typeof rootReducer>;

// Configuración de redux-persist con tipos
const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  transforms: [encryptor],
};

// Definimos explícitamente el tipo de estado en persistReducer
const persistedReducer = persistReducer<ApplicationState, any>(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  // Utilizamos una función de configuración para el middleware
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignora estas acciones
        ignoredActions: ['persist/PERSIST'],
        // Ignora estas rutas en el estado
        ignoredPaths: ['some.path.to.ignore', 'another.path.to.ignore'],
        // Ignora estas rutas en las acciones
        ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
      },
    }),
});

const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export { store, persistor };
