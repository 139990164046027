import { createTheme } from '@mui/material/styles'
import Red from '@mui/material/colors/red'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#003876'
    },
    secondary: {
      main: '#EE2A24'
    },
    background: {
      default: '#fafafa'
    }
  },

  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(',')
  },

  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'white',
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: '20px',
          fontWeight: '600',
          color: '#000',
          whiteSpace: 'normal',
          overflow: 'unset',

          '& span': {
            color: Red[500]
          }
        }
      }
    },
    MuiFormLabel: {
      defaultProps: {
        sx: {
          fontSize: '15px',
          fontWeight: '600',
          lineHeight: '29px',
          color: '#000',
          whiteSpace: 'normal',

          '& span': {
            color: Red[500]
          }
        }
      }
    }
  }
})
