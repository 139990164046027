import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FixedAssets } from '../../../../domain/types/Common';

const initialModelState: FixedAssets[] = [];

const modelSlice = createSlice({
  name: 'model',
  initialState: initialModelState,
  reducers: {
    setModels: (_state, action: PayloadAction<FixedAssets[]>) => {
      return action.payload;
    },
    clearModels: () => {
      return initialModelState;
    }
  }
});

export const { setModels, clearModels } = modelSlice.actions;
export default modelSlice.reducer;
