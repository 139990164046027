import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const homeRoutes: RouteType[] = [
  {
    permissions: [
      'dashboard.create.permission',
      'dashboard.edit.permission',
      'dashboard.delete.permission',
      'dashboard.read.permission'
    ],
    path: urlRoutes.management.dashboard.url,
    element: lazy(
      async () =>
        await import('ui/modules/management/dashboard/managementDashboard')
    )
  }
]
