import { useContext } from 'react'
import { GridContainer } from 'ui/shared/components/common/Grid/Grid'
import { ButtonApp } from 'ui/shared/components/common/ButtonText/ButtonText'
import { Grid } from '@mui/material'
import { TextSubTitle } from 'ui/shared/components/common/Typography/Typography'
import Logo from 'assets/logo.png'
import { AuthContext } from 'ui/contexts/auth/authProvider'
import { useAppDispatch } from 'core/util/stateManager/application/Dispatch'
import { clearSessionToken } from 'core/util/stateManager/application/slices/SessionSlice'
import { clearUser } from 'core/util/stateManager/application/slices/UserSlice'

export default function WithoutPermissions () {
  const { addAuthFN, setNonePermissions } = useContext(AuthContext)
  const dispatch = useAppDispatch();
  const handleLogout = () => {
    localStorage.clear()
    addAuthFN({})
    dispatch(clearSessionToken());
    dispatch(clearUser());
    setNonePermissions(false)
    window.location.reload()
  }

  return (
    <GridContainer marginX={ 1 } direction='column' itemsCenter marginY>
      <Grid item lg={ 1 }>
        <img alt='Logo' width={ 175 } src={ Logo } />
      </Grid>
      <Grid item lg={ 1 } sx={ { width: '50%' } }>
        <TextSubTitle>
          Este usuario no tiene permisos asignados para acceder a los módulos
          del sistema, favor comunicarse con el departamento Administrativo, de
          la Dirección de Lenguas Extranjeras
        </TextSubTitle>
      </Grid>
      <Grid item lg={ 1 } sx={ { width: '50%' } }>
        <ButtonApp onClick={ handleLogout }>Cerrar Sesión</ButtonApp>
      </Grid>
    </GridContainer>
  )
}
