import { useSelector } from 'react-redux';
import type { RootState } from 'core/util/stateManager/infrastructure/RootReducer';

export function useUserSelectors() {
  const userSelector = useSelector((state: RootState) => state.user);

  return {
    userSelector,
  };
}
