import { lazy } from 'react'

import { urlRoutes } from 'ui/constant/urls'

const NotFoundPublic = lazy(async () => await import('ui/modules/404/public/publicError'))

const Login = lazy(async () => await import('ui/modules/auth/login/login'))
const Register = lazy(async () => await import('ui/modules/auth/register/register'))
const RegisterValidate = lazy(async () => await import('ui/modules/auth/register/validate/registerValidate'))
const ForgotPassword = lazy(async () => await import('ui/modules/auth/forgotPassword/forgotPassword'))
const UpdatePassword = lazy(async () => await import('ui/modules/auth/updatePassword/updatePassword'))
const ResendEmail = lazy(async () => await import('ui/modules/auth/resendEmail/resendEmail'))

export const publicRoutes = [
  { path: '*', element: <NotFoundPublic /> },

  { path: urlRoutes.auth.login.url, element: <Login /> },
  { path: urlRoutes.auth.register.url, element: <Register /> },
  { path: urlRoutes.auth.registerValidate.url, element: <RegisterValidate /> },
  { path: urlRoutes.auth.forgotPassword.url, element: <ForgotPassword /> },
  { path: urlRoutes.auth.updatePassword.url, element: <UpdatePassword /> },
  { path: urlRoutes.auth.resendEmail.url, element: <ResendEmail /> }

]
