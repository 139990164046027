import { Suspense, useContext, useEffect } from 'react'
import { HashRouter as Router, Route, Routes } from 'react-router-dom'

import PublicRoutes from './publicRoutes'
import PrivatedRoutes from './privatedRoutes'

import PublicPages from './publicPages/publicPages'
import PrivatedPages from './privatedPages/privatedPages'

import { urlRoutes } from 'ui/constant/urls'
import { AuthContext } from 'ui/contexts/auth/authProvider'
import { useUserSelectors } from 'core/util/stateManager/application/selectors/UserSelector'

export default function Routers () {

  const { userSelector } = useUserSelectors()
  const { authState, addAuthFN } = useContext(AuthContext)

  useEffect(() => {
    if (userSelector) { 
      addAuthFN({ user: userSelector, logged: !!userSelector, })
    } else {
      addAuthFN({ user: null, logged: !!userSelector, })
    }
   
  }, [userSelector])

  return (
    <Router>
      <Suspense fallback={ <div>Cargando...</div> }>
        <div>
          <Routes>
            <Route element={ <PublicRoutes isLoggedIn={ authState?.auth?.logged } /> }>
              <Route path={ urlRoutes.auth.url } element={ <PublicPages /> } />
            </Route>

            <Route element={ <PrivatedRoutes isLoggedIn={ authState?.auth?.logged } /> }>
              <Route path={ '/*' } element={ <PrivatedPages /> } />
            </Route>
          </Routes>
        </div>
      </Suspense>
    </Router>
  )
}
