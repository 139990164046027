import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FixedAssets } from '../../../../domain/types/Common';

const initialLocationState: FixedAssets[] = [];

const locationSlice = createSlice({
  name: 'location',
  initialState: initialLocationState,
  reducers: {
    setLocations: (_state, action: PayloadAction<FixedAssets[]>) => {
      return action.payload;
    },
    clearLocations: () => {
      return initialLocationState;
    }
  }
});

export const { setLocations, clearLocations } = locationSlice.actions;
export default locationSlice.reducer;
