import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Swal from 'sweetalert2'

type IProps = {
  text?: string
}

export const AlertError = ({ text }: IProps) => {
  return (
    <Alert severity='error'>
      <AlertTitle>Error</AlertTitle>
      { text || 'Ocurrió un error al procesar la solicitud' }
    </Alert>
  )
}

export const AlertWarning = ({ text }: IProps) => {
  return (
    <Alert severity='warning'>
      { text }
    </Alert>
  )
}

export const SweetAlertError = async (text?: string) => {
  return await Swal.fire({
    icon: 'error',
    title: 'Error',
    text: text || 'Ocurrió un error al procesar la solicitud',
    confirmButtonColor: '#003876'
  })
}

export const SweetAlertWarning = async (text: string) => {
  return await Swal.fire({
    icon: 'warning',
    title: 'Aviso',
    text,
    confirmButtonColor: '#003876'
  })
}

export const SweetAlertSuccess = async (text?: string) => {
  return await Swal.fire({
    icon: 'success',
    title: 'Correcto',
    text: text || 'Proceso realizado correctamente',
    confirmButtonColor: '#003876'
  })
}

type IPropsModal = {
  title: string
  message: string
  icon: 'question' | 'info'
  fn: any;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export const SweetAlertModal = async ({ title, message, icon, fn, confirmButtonText, cancelButtonText }: IPropsModal) => {
  await Swal.fire({
    title,
    text: message,
    icon,
    showCancelButton: true,
    confirmButtonColor: '#003876',
    cancelButtonColor: '#EE2A24',
    confirmButtonText: confirmButtonText || 'Continuar',
    cancelButtonText: cancelButtonText || 'Cancelar'
  }).then((result) => {
    if (result.isConfirmed) {
      fn()
    }
  })
}
