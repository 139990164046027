import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  applicant: 'student'
};

const applicantSlice = createSlice({
  name: 'applicant',
  initialState,
  reducers: {
    setApplicant: (state, action: PayloadAction<string>) => {
      state.applicant = action.payload;
    },
  }
});

export const { setApplicant } = applicantSlice.actions;
export default applicantSlice.reducer;
