export const rols = {
  superAdmin: '*',
  admin: 'Admin',
  teacher: 'Profesor',
  teacherCharge: 'Profesor(a) Encargado(a)',
  coordinator: 'Coordinador',
  admissionsOfficer: 'Encargado(a) Admision',
  admissionBracket: 'Soporte de Admisiones',
  teacherApplicant: 'Aplicante Profesor',
  applicant: 'Aplicante',
  student: 'Estudiante'
}
