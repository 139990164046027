import { urlRoutes } from 'ui/constant/urls'

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined'
import MoveDownOutlinedIcon from '@mui/icons-material/MoveDownOutlined'
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';

export const drawerList = [
  {
    items: [
      {
        icon: <HomeOutlinedIcon />,
        text: 'Inicio',
        link: urlRoutes.applicant.home.urlTo,
        children: []
      }
    ]
  },
  {
    items: [
      {
        icon: <PrivacyTipOutlinedIcon />,
        text: 'Reglamentos',
        link: urlRoutes.applicant.regulation.urlTo,
        children: []
      }
    ]
  },
  {
    module: null,
    items: [
      {
        itemId: 0,
        icon: <MoveDownOutlinedIcon />,
        text: 'Movimiento',
        children: [
          { text: 'Transferencia', link: urlRoutes.applicant.movement.transfer.url },
          { text: 'Retiro', link: urlRoutes.applicant.movement.retire.url }
        ]
      }
    ]
  },
  {
    items: [
      {
        icon: <HistoryEduOutlinedIcon />,
        text: 'Carta de constancia',
        link: urlRoutes.applicant.config.letter.urlTo,
        children: []
      }
    ]
  },
  {
    items: [
      {
        icon: <WorkspacePremiumOutlinedIcon />,
        text: 'Certificados de graduado',
        link: urlRoutes.applicant.config.certificado.url,
        children: []
      }
    ]
  },
  {
    module: null,
    items: [
      {
        itemId: 1,
        icon: <SettingsOutlinedIcon />,
        text: 'Configuración',
        children: [
          { text: 'Mi información', link: urlRoutes.applicant.config.myInformation.urlTo },
        ]
      }
    ]
  }
]
