import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FixedAssets } from '../../../../domain/types/Common';

const initialActiveState: FixedAssets[] = [];

const activeSlice = createSlice({
  name: 'active',
  initialState: initialActiveState,
  reducers: {
    setActives: (_state, action: PayloadAction<FixedAssets[]>) => {
      return action.payload;
    },
    clearActives: () => {
      return [];
    }
  }
});

export const { setActives, clearActives } = activeSlice.actions;
export default activeSlice.reducer;
