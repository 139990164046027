import HttpClient from 'core/util/httpClient/application/HttpClient';
import { endPoints } from 'core/util/httpClient/domain/constants/endpoints';
import type INotificationRepository from '../domain/repository/notification.repository';

class NotificationProvider implements INotificationRepository {
  protected readonly httpAdapter;

  constructor() {
    this.httpAdapter = HttpClient.getInstance().getHttpAdapter();
  }

  async getNotification(data: any) {
    return await this.httpAdapter.get(endPoints.notification.get(data), data);
  }

  async deleteNotification(id: string) {
    return await this.httpAdapter.delete(endPoints.notification.delete(id));
  }

  async markAsRead(id: any) {
    return await this.httpAdapter.post(endPoints.notification.read, { id });
  }
}

export default NotificationProvider;
