import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { FixedAssets } from '../../../../domain/types/Common';

const initialStatusActiveState: FixedAssets[] = [];

const statusActiveSlice = createSlice({
  name: 'statusActive',
  initialState: initialStatusActiveState,
  reducers: {
    setStatusActives: (_state, action: PayloadAction<FixedAssets[]>) => {
      return action.payload;
    },
    clearStatusActives: () => {
      return initialStatusActiveState;
    }
  }
});

export const { setStatusActives, clearStatusActives } = statusActiveSlice.actions;
export default statusActiveSlice.reducer;
